import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './style.css';

function Student() {
  // State to store exam data
  const [exams, setExams] = useState([]);

  // Function to fetch exam data
  const fetchExamData = async () => {
    try {
      const response = await axios.get('http://axd3080.uta.cloud/wdm_backend/php/fetch_all_exams.php');
      const { exams } = response.data;

      console.log('Fetched exams:', exams);

      // Update the task bar with the latest exam data
      setExams(exams);
    } catch (error) {
      console.error('Error fetching exam data:', error);
    }
  };

  useEffect(() => {
    // Call the fetchExamData function when the component mounts
    fetchExamData();

    // Poll for updates every 5 seconds (adjust the interval as needed)
    const intervalId = setInterval(fetchExamData, 5000);

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  // Sample course data
  const courses = [
    { name: 'Machine Learning', link: '/student_view' },
    { name: 'WDM', link: '/student_view2' },
    { name: 'DAA', link: '/student_view3' },
  ];

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Student Dashboard</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <a href="/">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </a>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/student" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                <Link to="/" className="header-title">LOGOUT</Link>
                {/* <span className="header-title" style={{ cursor: 'pointer' }}>LOGOUT</span> */}
              </div>
            </div>
            <div className="main-content-container">
              <div className="qa-container">
                <nav>
                  <ul>
                    <li>
                      <a className="logo qa-link">
                        <span className="nav-item">
                          <Link to="/student">Student Dashboard</Link>
                        </span>
                      </a>
                    </li>
                    <li>
                      <span className="nav-item">
                        <Link to="/overview" className="qa-link">Overview</Link>
                      </span>
                    </li>
                    <li>
                      <span className="nav-item">
                        <Link to="/contactUs" className="qa-link">Issue</Link>
                      </span>
                    </li>
                    <li>
                      <span className="nav-item">
                        <Link to="/settings_foreveryrole" className="qa-link">Settings</Link>
                      </span>
                    </li>
                  </ul>
                </nav>
                <section className="main">
                  <div className="main-top">
                    <h1>Courses</h1>
                  </div>
                  <div className="main-skills">
                    {courses.map((course, index) => (
                      <div className="card" key={index}>
                        <h3>{course.name}</h3>
                        <button>
                          <Link to={course.link} className="button-links">
                            View
                          </Link>
                        </button>
                      </div>
                    ))}
                    <div className="card">
                      <h3>Course Info</h3>
                      <button>
                        <Link to="/student_courseinfo" className="button-links">
                          View
                        </Link>
                      </button>
                    </div>
                  </div>
                  <section className="main-course">
                    <h1>To-Do</h1>
                    <div className="course-box">
                      <br />
                      <br />
                      <h1 style={{ textAlign: 'left' }}>Assignment Due</h1>
                      <br />
                      <p style={{ textAlign: 'left' }}>Assignment-1 is due on 10/05/2023</p>
                      <br />
                      <h1 style={{ textAlign: 'left' }}>Exam Due</h1>
                      <br />
                      {exams.map((exam, index) => (
                        <p key={index} style={{ textAlign: 'left' }}>{exam.instructor_mail} is due on {exam.Exam_date}</p>
                      ))}
                    </div>
                  </section>
                </section>
              </div>
              <div className="chat-feedback">
                <a href="./feedback" className="feedback-link">
                  Feedback
                </a>
                <div className="chat-icon">
                  <a href="./chat.html" className="chat-hover">
                    <Link to="/chat">
                      <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                  </a>
                </div>
              </div>
              <div className="chat-feedback">
                <Link to="/feedback" className="feedback-link">
                  Feedback
                </Link>
                <div className="chat-icon">
                  <a className="chat-hover">
                    <Link to="/chat">
                      <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                  </a>
                </div>
              </div>
              <div className="footer">
                <div className="footer-content">
                  <div className="footer-left">
                    <div className="about-text-footer">
                      <br />
                      College Of Computer Science
                      <br />
                      634 Nedderman Hall
                      <br />
                      Arlington, TX 76019
                    </div>
                  </div>
                  <div className="footer-right">
                    <div className="footer-links">
                      <Link to="/" className="footer-links-title">
                        HOME
                      </Link>
                      <Link to="/aboutus" className="footer-links-title">
                        ABOUT
                      </Link>
                      <Link to="/services" className="footer-links-title">
                        SERVICES
                      </Link>
                      <Link to="/contactUs" className="footer-links-title">
                        CONTACT US
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div className="footer-social-icons">
                    <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                    <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                    <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                    <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                  </div>
                  <br />
                  @GradEdge Copyright 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default Student;
