import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Container = styled.div`
  max-width: 600px;
  margin: 40px auto 20px; /* Adjusted margin-top to 40px */
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
`;

const QuestionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const QuestionItem = styled.li`
  margin-bottom: 20px;
`;

const OptionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const OptionItem = styled.li`
  margin-top: 10px;
`;

const RadioInput = styled.input`
  margin-right: 5px;
`;

const Label = styled.label`
  cursor: pointer;
  font-family: 'Verdana', sans-serif;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
`;

const QuizStudent = () => {
  const [quizId, setQuizId] = useState(6); // Set the initial quiz ID
  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await fetch(`https://vxd1553.uta.cloud/php/fetch_quiz_questions.php/?quiz_id=${quizId}`);
        const data = await response.json();

        if (data.success) {
          // Initialize selectedOptions state based on fetched questions
          const initialSelectedOptions = {};
          data.questions.forEach((question) => {
            initialSelectedOptions[question.question_id] = null;
          });

          setQuestions(data.questions);
          setSelectedOptions(initialSelectedOptions);
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error('Error fetching questions:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [quizId]);

  const handleQuizIdChange = (e) => {
    setQuizId(e.target.value);
  };

  const handleOptionChange = (questionId, option) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [questionId]: option,
    }));
  };

//   const handleSubmitQuiz = () => {
    // const handleSubmitQuiz = async () => {
    //     try {
    //       const userEmail = sessionStorage.getItem('email');
    //       console.log('User Email:', userEmail);
    //       console.log('Selected Options:', selectedOptions);
      
    //       const response = await fetch(`https://vxd1553.uta.cloud/php/student_response_quiz.php`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //           email: userEmail,
    //           quiz_data: selectedOptions,
    //         }),
    //       });
      
    //       const data = await response.json();
      
    //       if (data.success) {
    //         console.log('Quiz responses submitted successfully.');
    //         // You may want to perform additional actions upon successful submission
    //       } else {
    //         console.error('Failed to submit quiz responses:', data.message);
    //       }
    //     } catch (error) {
    //       console.error('Error submitting quiz responses:', error.message);
    //     }
    //   };
    const handleSubmitQuiz = async () => {
        try {
          const userEmail = sessionStorage.getItem('email');
          console.log('User Email:', userEmail);
          console.log('Selected Options:', selectedOptions);
    
          const response = await fetch(`https://vxd1553.uta.cloud/php/student_response_quiz.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: userEmail,
              quiz_data: selectedOptions,
            }),
          });
    
          const data = await response.json();
    
          if (data.success) {
            console.log('Quiz responses submitted successfully.');
           alert('Quiz responses submitted successfully.', { autoClose: 2000 });
            // Reload the page after a delay (e.g., 2 seconds)
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            console.error('Failed to submit quiz responses:', data.message);
            alert.error(`Failed to submit quiz responses: ${data.message}`);
          }
        } catch (error) {
          console.error('Error submitting quiz responses:', error.message);
          alert.error(`Error submitting quiz responses: ${error.message}`);
        }
      };
    


  return (
    <Container>
      <h1>Quiz Questions</h1>
      <label>
        Select Quiz ID:
        <input type="number" value={quizId} onChange={handleQuizIdChange} />
      </label>

      {loading ? (
        <p>Loading questions...</p>
      ) : (
        <div>
          {questions.length > 0 ? (
            <form>
              <QuestionList>
                {questions.map((question) => (
                  <QuestionItem key={question.question_id}>
                    <p>{question.question_text}</p>
                    <OptionList>
                      {[1, 2, 3, 4].map((option) => (
                        <OptionItem key={option}>
                          <RadioInput
                            type="radio"
                            id={`q${question.question_id}_option${option}`}
                            name={`q${question.question_id}`}
                            value={option}
                            checked={selectedOptions[question.question_id] === option}
                            onChange={() => handleOptionChange(question.question_id, option)}
                          />
                          <Label htmlFor={`q${question.question_id}_option${option}`}>
                            {question[`option_${option}`]}
                          </Label>
                        </OptionItem>
                      ))}
                    </OptionList>
                  </QuestionItem>
                ))}
              </QuestionList>
              <SubmitButton type="button" onClick={handleSubmitQuiz}>
                Submit Quiz
              </SubmitButton>
            </form>
          ) : (
            <p>No questions found for the selected quiz ID.</p>
          )}
        </div>
      )}
    </Container>
  );
};

export default QuizStudent;

