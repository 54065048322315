// NewChat.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function NewChat() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://vxd1553.uta.cloud/php/get_users.php');
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleStartChat = async () => {
    try {
      if (!selectedUser) {
        const errorMessage = 'Please select a participant before starting the chat.';
        setError(errorMessage);
        window.alert(errorMessage);
        return;
      }
      const response = await axios.post(`https://vxd1553.uta.cloud/php/startChat.php?userID=${localStorage.getItem("id")}`, {
        participant: selectedUser,
      });
      const lastInsertedId = response.data;

      localStorage.setItem('chatid', lastInsertedId);
      navigate(`/chat/${lastInsertedId}`);
      console.log(response.data);
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  return (
    <div>
      <style>
        {`
          body {
            font-family: 'Arial', sans-serif;
            background-color: #f8f9fa;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
          }

          .container {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 30px;
            width: 400px;
            text-align: center;
          }

          .home-text {
            font-size: 24px;
            color: #007bff;
            margin-bottom: 20px;
          }

          .label-chat {
            font-size: 16px;
            color: #333;
            margin-bottom: 10px;
            display: block;
            text-align: left;
          }

          select {
            width: 100%;
            padding: 12px;
            margin-bottom: 20px;
            font-size: 16px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
          }

          .button-link {
            display: inline-block;
            padding: 12px 20px;
            background-color: #007bff;
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          .button-link:hover {
            background-color: #0056b3;
          }
        `}
      </style>

      <div className='container'>
        <h1 className="home-text">Start a New Chat</h1>
        <label className="label-chat" htmlFor="Participant">Select Participant:</label>
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="" disabled>Select user...</option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.email}
            </option>
          ))}
        </select>
        <br />
        <button className="button-link" onClick={handleStartChat}>Start Chat</button>
      </div>
    </div>
  );
}

export default NewChat;
  