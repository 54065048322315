// import React from 'react';
// import './style.css'; // Import your CSS file
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function StudentIssue() {
//   const history = useHistory();

//   const handleLogout = () => {
//     const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//     if (confirmLogout) {
//       history.push('/login'); // Replace with your login page route
//     }
//     // If the user chooses not to log out, the function will simply exit without any additional actions
//   };
//   return (
//     <div>
//       <div className="header">
//         <div className="header-left">
//         <div className="header-title">
//             <a href="./">
//                  <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//             </a>
//             <span className="heading">GradEdge</span>
//         </div>
//         </div>
//         <div className="header-right">
//             <Link to="/student" className="header-title">HOME</Link>
//             <Link to="/aboutus" className="header-title">ABOUT</Link>
//             <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>
//            {/* <Link to="/" className="header-title">LOGOUT</Link> */}
//                             </div>
//       </div>
//       <div className="main-content-container">
//         <div className="container">
//           <div className="form-container">
//             <h2>Add Issue!</h2>
//             <p className="highlighted-text">Feel free to connect with us via:</p>
//             <div className="contact-info-box">
//               <ul className="contact-details-list">
//                 <li>Email: <a href="mailto:gradedge@gmail.com">gradedge@gmail.com</a></li>
//                 <li>Phone: +1 (123) 456-7890</li>
//                 <li>Address: 123 Main Street, City, Country</li>
//               </ul>
//             </div>
//             <form action="#" method="POST">
//               <div className="form-group">
//                 <label htmlFor="name">Name:</label>
//                 <input type="text" id="name" name="name" required />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="email">Email:</label>
//                 <input type="email" id="email" name="email" required />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="message">Message:</label>
//                 <textarea id="message" name="message" rows="4" required></textarea>
//               </div>
//               <button type="submit">Submit</button>
//             </form>
//           </div>
          
//         </div>
       
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/" className="footer-links-title">
//   HOME
// </Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//       </div>
//     </div>
//   );
// }

// export default StudentIssue;

import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function StudentIssue() {
  return (
    <div>
      <div className="header">
        <div className="header-left">
          <div className="header-title">
            <a href="./">
              <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
            </a>
            <span className="heading">GradEdge</span>
          </div>
        </div>
        <div className="header-right">
          <Link to="/student" className="header-title">HOME</Link>
          <Link to="/aboutus" className="header-title">ABOUT</Link>
          <span className="header-title" style={{ cursor: 'pointer' }}>LOGOUT</span>
        </div>
      </div>
      <div className="main-content-container">
        <div className="container">
          <div className="form-container">
            <h2>Add Issue!</h2>
            <p className="highlighted-text">Feel free to connect with us via:</p>
            <div className="contact-info-box">
              <ul className="contact-details-list">
                <li>Email: <a href="mailto:gradedge@gmail.com">gradedge@gmail.com</a></li>
                <li>Phone: +1 (123) 456-7890</li>
                <li>Address: 123 Main Street, City, Country</li>
              </ul>
            </div>
            <form action="#" method="POST">
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" rows="4" required></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <div className="about-text-footer">
                <br />
                College Of Computer Science
                <br />
                634 Nedderman Hall
                <br />
                Arlington, TX 76019
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-links">
                <Link to="/" className="footer-links-title">
                  HOME
                </Link>
                <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                <Link to="/services" className="footer-links-title">
                  SERVICES
                </Link>
                <Link to="/contactUs" className="footer-links-title">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
          <div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentIssue;
