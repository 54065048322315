import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const Signup = () => {
  const minPasswordLength = 8;
  const [formData, setFormData] = useState({
    tableName: 'register',
    columns: {
      f_name: '',
      l_name: '',
      role: 'student',
      email: '',
      password: '',
      mobile: '',
    },
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input separately to set a boolean value
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios
      .post('https://vxd1553.uta.cloud/php/register.php', formData)
      .then(function (response) {
        console.log(response.data); // Log the entire response
  
        if (response.data && response.data.success !== undefined) {
          // Check if 'success' property exists in the response
          if (!response.data.success) {
            // Registration failed
            alert(response.data.message || 'Registration failed. Please try again.');
            return; // Stop further processing if registration failed
          }
        }
  
        if (response.data && response.data.passwordValidation !== undefined) {
          // Check if 'passwordValidation' property exists in the response
          if (!response.data.passwordValidation) {
            // Password validation failed
            switch (response.data.criteria) {
              case 'length':
                alert('Password must be at least ' + minPasswordLength + ' characters long');
                break;
              case 'uppercase':
                alert('Password must contain at least one uppercase letter');
                break;
              case 'lowercase':
                alert('Password must contain at least one lowercase letter');
                break;
              case 'digit':
                alert('Password must contain at least one digit');
                break;
              case 'specialChar':
                alert('Password must contain at least one special character');
                break;
              default:
                alert('Invalid password');
                break;
            }
            return; // Stop further processing if password validation failed
          }
        }
  
        if (response.data && response.data.mobileValidation !== undefined) {
          // Check if 'mobileValidation' property exists in the response
          if (!response.data.mobileValidation) {
            // Mobile number validation failed
            alert('Mobile number must be exactly 10 digits');
            return; // Stop further processing if mobile number validation failed
          }
        }
  
        // Registration was successful
        alert('Your Registration is Successful. An Email is sent to your Email.');
        // Optionally, you can redirect the user to another page
      })
      .catch(function (error) {
        console.error('Error during registration:', error);
        alert('Registration failed. Please try again.');
      });
  };
  
  
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Signup</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <Link to="/">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </Link>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/" className="header-title">
                  HOME
                </Link>
                <Link to="/aboutus" className="header-title">
                  ABOUT
                </Link>
                <Link to="/signup" className="header-title">
                  SIGNUP
                </Link>
                <Link to="/login" className="header-title">
                  LOGIN
                </Link>
                <Link to="/settings_foreveryrole" className="header-title">
                  PROFILE
                </Link>
              </div>
            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Signup</h2>
                  <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Firstname" name="f_name" value={formData.columns.f_name} onChange={handleChange} required />
                    <input type="text" placeholder="Lastname" name="l_name" value={formData.columns.l_name} onChange={handleChange} required />
                    <input type="text" placeholder="Role" name="role" list="roles" value={formData.columns.role} onChange={handleChange} required />
                    <datalist id="roles">
                      <option value="Student" />
                      <option value="Instructor" />
                      <option value="Program Coordinator" />
                      <option value="QA Officer" />
                    </datalist>
                    <input type="email" placeholder="Email" name="email" value={formData.columns.email} onChange={handleChange} required />
                    <input type="password" placeholder="Password" name="password" value={formData.columns.password} onChange={handleChange} required />
                    <input
                      type="text"
                      placeholder="mobile"
                      name="mobile"
                      value={formData.columns.mobile}
                      onChange={handleChange}
                      required
                    />
                    <div className="signup-checkbox">
                      <label>
                        <input type="checkbox" name="remember" /> Remember Me
                      </label>
                    </div>
                    <button type="submit">Signup</button>
                  </form>
                  <p>
                    Already have an account?<Link to="/login">LOGIN</Link>
                  </p>
                </div>
              </div>
              <div className="chat-feedback">
                <Link to="/feedback" className="feedback-link">
                  Feedback
                </Link>
                <div className="chat-icon">
                  <Link to="/chat">
                    <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                  </Link>
                </div>
              </div>
              <div className="footer">
                <div className="footer-content">
                  <div className="footer-left">
                    <div className="about-text-footer">
                      <br />
                      College Of Computer Science
                      <br />
                      634 Nedderman Hall
                      <br />
                      Arlington, TX 76019
                    </div>
                  </div>
                  <div className="footer-right">
                    <div className="footer-links">
                      <Link to="/" className="footer-links-title">
                        HOME
                      </Link>
                      <Link to="/aboutus" className="footer-links-title">
                        ABOUT
                      </Link>
                      <Link to="/services" className="footer-links-title">
                        SERVICES
                      </Link>
                      <Link to="/contactUs" className="footer-links-title">
                        CONTACT US
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <br />
                  <div className="footer-social-icons">
                    <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                    <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                    <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                    <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                  </div>
                  <br />
                  @GradEdge Copyright 2023
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default Signup;
