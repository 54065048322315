import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

const Login = () => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send login data to the backend for authentication
    axios
      .post('https://vxd1553.uta.cloud/php/login.php', userData)
      .then((response) => {
        // const { success, role, id } = response.data;
        const { data } = response; 
        if (data.success === 1) {
          // Store login data in localStorage
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('role', data.role);
          localStorage.setItem('id',data.id);
          sessionStorage.setItem('loggedIn', 'true');

          sessionStorage.setItem('role', data.role);
          sessionStorage.setItem('email', data.email);
          sessionStorage.setItem('id',data.id);

          // Redirect to the appropriate dashboard based on the user role
          switch (data.role) {
            case 'admin':
              navigate('/admin');
              break;
            case 'Instructor':
              navigate('/instructor');
              break;
            case 'Student':
              navigate('/student');
              break;
            case 'QA Officer':
              navigate('/qaofficer');
              break;
            case 'Program Coordinator':
              navigate('/program_co');
              break;
            default:
               //Handle unknown role or error case
               console.error('Unknown role:', data.role);
          }
        } else {
          alert(data.alertMessage);
              }
      })
      .catch((error) => {
        console.error(error);
        // Handle login failure, e.g., show an error message
      });
  };
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Login</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
          <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="./">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/"className="header-title">HOME</Link>
                  {/* <Link to="/student"className="header-title">STUDENT</Link>
                  <Link to="/instructor"className="header-title">INSTRUCTOR</Link>
                  <Link to="/admin"className="header-title">ADMIN</Link>
                  <Link to="/qaofficer"className="header-title">QA OFFICER</Link>
                  <Link to="/program_co"className="header-title">PROG CORDINATOR</Link> */}
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
                  </div>            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Login</h2>
                  <form onSubmit={handleSubmit}>
                   
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                  
                      required
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={userData.password}
                      onChange={handleChange}
                      
                      required
                    />
                    <button type="submit">Login</button>
                  </form>
                  <p>Don't have an account? <Link to="/signup">SIGNUP</Link></p>
                  <p> <Link to="/forgot_password">Forgot Password?</Link></p>
                </div>
              </div>
              
            </div>
             <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
            {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        
      </body>
    </html>
  );
}

export default Login;