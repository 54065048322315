

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

const ManagePolicies= () => {
  const [policies, setpolicies] = useState([]);
  const [updateData, setUpdateData] = useState({
    policy_name:'',
      policy_desc:''
  });
  const [editing, setEditing] = useState({});  //Track editing status for each score

  useEffect(() => {
    getAllUsers();
  }, []);

  async function getAllUsers() {
    try {
      const response = await axios.get('https://vxd1553.uta.cloud/php/qa_fetch_policy.php');
      const { policies } = response.data;
      setpolicies(policies);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function deleteEntry(policy_id) {
    try {
      await axios.post('https://vxd1553.uta.cloud/php/qa_delete_policy.php', {
        policy_id:  policy_id // Send the student_id in the request body
      });
      alert("Policy deleted successfully.");
      getAllUsers();
    } catch (error) {
      console.error("Error deleting Policy:", error);
    }
  }

async function updateEntry(policy_id, policy_name,policy_desc ) {
    try {
      await axios.post('https://vxd1553.uta.cloud/php/qa_update_policy.php', {
        policy_id,
        policy_name: policy_name,
        policy_desc: policy_desc,
      });
  
       //Fetch and update the policies after a successful update
      const response = await axios.get('https://vxd1553.uta.cloud/php/qa_fetch_policy.php');
      const { policies } = response.data;
      setpolicies(policies);
  
      alert('Policy updated successfully.');
      setEditing({ ...editing, [policy_id]: false });  //Stop editing
    } catch (error) {
      console.error('Error updating Policy:', error);
    }
  }
  const handleLogout = () => {
     //Handle logout
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          {/* ... (Your header code) ... */}
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Manage Policies</h2>
            <table className="policy-table">
              <thead>
                <tr>
                  <th>ID</th>
                  
                  <th>Policy Name</th>
                  <th>Policy Desc</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(policies) && policies.length > 0 ? (
                  policies.map((user, index) => (
                    <tr key={index}>
                      <td>{user.policy_id}</td>
                      {/* <td>{user.policy_name}</td>
                      <td>{user.policy_desc}</td> */}
                      
                      <td>
                        {editing[user.policy_id] ? (
                          <input
                            type="text"
                            value={updateData.policy_name}
                            onChange={(e) => setUpdateData({ ...updateData, policy_name: e.target.value })}
                          />
                        ) : (
                          user.policy_name
                        )}
                      </td>
                     
                      <td>
                        {editing[user.policy_id] ? (
                          <input
                            type="text"
                            value={updateData.policy_desc}
                            onChange={(e) => setUpdateData({ ...updateData, policy_desc: e.target.value })}
                          />
                        ) : (
                          user.policy_desc
                        )}
                      </td>
                      <td>

               {editing[user.policy_id] ? (
                            <span className="action-icon" onClick={() => updateEntry(user.policy_id,updateData.policy_name,updateData.policy_desc)}>
                              &#128449; {/* Save Folder Icon */}
                            </span>
                          ) : (
                            <span className="action-icon" onClick={() => setEditing({ ...editing, [user.policy_id]: true })}>
                              &#9998; {/* Pencil Icon */}
                            </span>
                          )}
                         
              
      <span className="action-icon"  onClick={() => deleteEntry(user.policy_id)}>&#128465;</span>

               <Link to="/qa-policies">
                 <span className="action-icon">&#x2795;</span>
               </Link>
     
             </td>
             </tr>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ManagePolicies;