

import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function PerformanceData() {
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <a href="./">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </a>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/qaofficer" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <span className="header-title">LOGOUT</span>
          </div>
        </div>
        <div className="report-container">
          <h1>Performance Reports</h1>
          <p>Select user type:</p>
          <form>
            <input type="text" placeholder="Role" name="role" list="roles" required />
            <datalist id="roles">
              <option value="Student" />
              <option value="Instructor" />
            </datalist>
            <br />
            <img src="./chart.svg" alt="image1" className="qa-img" />
          </form>
          <div className="main-content-container">
            <div className="container">
              <div className="form-container">
                <h2>Give Your Guidance!!</h2>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message:</label>
                  <textarea id="message" name="message" rows="4" required />
                </div>
                <button type="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer-content">
            <div className="footer-left">
              <div className="about-text-footer">
                <br />
                College Of Computer Science
                <br />
                634 Nedderman Hall
                <br />
                Arlington, TX 76019
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-links">
                <Link to="/" className="footer-links-title">
                  HOME
                </Link>
                <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                <Link to="/services" className="footer-links-title">
                  SERVICES
                </Link>
                <Link to="/contactUs" className="footer-links-title">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
          <div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerformanceData;
