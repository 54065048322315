import React from 'react';
import './style.css'; // Import your CSS file
import { Link } from "react-router-dom";


function AboutUs() {
  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" type="text/css" href="style.css" />
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="./">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/" className="header-title">HOME</Link>
                    <Link to="/aboutus"className="header-title">ABOUT</Link>
                    <Link to="/signup"className="header-title">SIGNUP</Link>
                    <Link to="/login"className="header-title">LOGIN</Link>
                    <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>

                  </div>
            </div>
            <div className="main-content-container">
              <div className="aboutUs-container">
                <h1>About Us</h1>
                <img src="./images/a.png" alt="image1" width="500" height="333" />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Praesent aliquam quam vel
                  magna congue, a lacinia urna dictum. Nunc eleifend, ipsum non pharetra euismod, mi velit congue nisl,
                  a ultrices nunc libero ut justo.
                </p>

                <p>
                  Integer efficitur, enim a convallis eleifend, lectus quam fringilla augue, et elementum enim libero
                  quis purus. Vestibulum venenatis scelerisque dui, in scelerisque justo aliquet ut.
                </p>

                <p>
                  Phasellus fringilla viverra turpis, vel semper nunc mattis at. Pellentesque tempor, turpis non
                  eleifend bibendum, eros justo cursus quam, non pharetra quam justo a purus. Sed vitae aliquam arcu.
                </p>

                <p>
                  Morbi tristique magna a felis scelerisque, vel elementum ligula dictum. Vestibulum volutpat tincidunt
                  massa, at fermentum elit hendrerit in. Vivamus vitae tristique metus, sit amet aliquet lectus. Sed
                  bibendum ex at urna efficitur, quis congue turpis malesuada.
                </p>
              </div>
               <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
            {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        </div>
        <iframe
          frameborder="0"
          scrolling="no"
          style={{ backgroundColor: 'transparent', border: '0px', display: 'none' }}
        />
        <div
          id="GOOGLE_INPUT_CHEXT_FLAG"
          style={{ display: 'none' }}
          input=""
          input_stat='{"tlang":true,"tsbc":true,"pun":true,"mk":true,"ss":true}'
        />
      </body>
    </html>
  );
}

export default AboutUs;
