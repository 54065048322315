
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './style.css';

const Student = () => {
    const [scores, setscores] = useState([]);
    const [suggestion, setSuggestion] = useState('');
        useEffect(() => {
        
        getAllScores();
        }, []); 
    const [result, setresult] = useState([]);

    useEffect(() => {
        getAllUsers();
    }, []);

    async function getAllUsers() {
        try {
            const response = await axios.get(`https://vxd1553.uta.cloud/php/fetch_score_vd.php`);
            const { data } = response;

            if (data.success === 1) {
                // Check if the data contains totalScore and totalMaxScore
                if ('totalScore' in data && 'totalMaxScore' in data) {
                    setresult(data);
                } else {
                    console.error('Invalid data format:', data);
                }
            } else {
                console.error('Error from server:', data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    async function  getAllScores  ()  {
        try {
        const userEmail = 'vidhidesai.ict17@gmail.com'
        const response = await axios.get(`https://vxd1553.uta.cloud/php/fetch_student_score.php?email=${userEmail}`); 
        const {scores} =response.data;
        setscores(scores); // Update state with fetched data
        } catch (error) {
        console.error('Error fetching data:', error);
        }
    }
    const data1 = {
        totalScore: result.totalScore ? parseInt(result.totalScore, 10) : 0,
        totalMaxScore: result.totalMaxScore ? parseInt(result.totalMaxScore, 10) : 0
    };


    const handleSuggestionChange = (e) => {
        setSuggestion(e.target.value);
    };

    const handleSubmitSuggestion = (e) => {
        e.preventDefault();
        // Add logic to send the suggestion to the server or perform any desired action
        console.log('Suggestion submitted:', suggestion);
        // Optionally, you can clear the suggestion field after submission
        setSuggestion('');
    };
// function Student() {
return (
    <html lang="en">
    <head>
        <link rel="stylesheet" href="style.css" />
        <title>Student Analytics</title>
        <style>
                    {`
                        .suggestion-form {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 20px;
                        }

                        .suggestion-form textarea {
                            width: 300px; /* Adjust the width as needed */
                            margin-top: 10px; /* Adjust the margin as needed */
                        }
                    `}
                </style>
    </head>
    <body className="vsc-initialized">
        <div id="root">
        <div className="App">
            <div className="header">
            <div className="header-left">
                <div className="header-title">
                <a href="/">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                </a>
                <span className="heading">GradEdge</span>
                </div>
            </div>
            <div className="header-right">
                <Link to="/student" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                <Link to="/" className="header-title">LOGOUT</Link>
            </div>
            </div>
            <div className="main-content-container">
            <div className="qa-container">
                <section className="main">
                <div className="main-top" style={{ marginLeft: '70px' }}>
                    <h1>Student Report</h1>
                    <i className="fas fa-user-cog"></i>
                </div>
                <div className="main-skills" style={{ marginLeft: '100px' }}>
                    <div className="card" style={{ marginLeft: '70px' }}>
                    <i className="fas fa-laptop-code"></i>
                    <h3>Total marks</h3>
                    <p></p>

                
                    <button>{data1.totalScore}</button>
                 

                    </div>
                    <div className="card">
                    <i className="fab fa-wordpress"></i>
                    <h3>Scored Marks</h3>

                    <button>
                     {data1.totalScore}
                    </button> 
                    </div>
                    <div className="card">
                    <i className="fas fa-palette"></i>
                    <h3>Percentage</h3>
                    

                    {<button>
                     
                        {Math.round((data1.totalScore / data1.totalMaxScore) * 100)}%
                    </button> }
                    </div>
                </div>
                </section>
                
                </div>
                <section>
                    <table className="policy-table">
                <thead>
                    <tr>
                    {/* <th>ID</th>
                    <th>CourseID</th> */}
                    {/*x   <th>Course name</th> */}
                    <th>Student email</th>
                    <th>Instructor email</th>
                    <th>Exam date</th>
                    <th>Your score</th>
                    <th>Max score</th>
                    {/* <th>Action</th> */}
                    </tr>
                    </thead>
                <tbody>
                
                {Array.isArray(scores) && scores.length > 0 ?(scores.map((user, index) => (
                    
                    <tr key={index}>
                    {/* <td>{user.Exam_id}</td> */}
                        {/* <td>{user.Course_id}</td> */}
                        <td>{user.student_email}</td>
                        <td>{user.instructor_mail}</td>
                        <td>{user.Date}</td>
                        <td>{user.score}</td>
                        <td>{user.max_score}</td>
                
                    </tr>
                    ))):""
                }
                </tbody></table>
                </section>
                <section className="suggestion-form" style={{ marginTop: '100px' }}>
                        <h2>Suggestion Form</h2>
                        <form onSubmit={handleSubmitSuggestion}>
                            <label htmlFor="suggestion">Your Suggestion:</label>
                            <textarea
                                id="suggestion"
                                name="suggestion"
                                value={suggestion}
                                onChange={handleSuggestionChange}
                                rows="4"
                                cols="50"
                                style={{ width: '300px',display: 'flex' }}
                            />
                            <br />
                            <button type="submit">Submit Suggestion</button>
                        </form>
                    </section>
                <div>
       
            </div>
            
            <div className="chat-feedback">
                <a href="./feedback.html" className="feedback-link">
                Feedback
                </a>
                <div className="chat-icon">
                <a href="./chat.html" className="chat-hover">
                    <Link to="/chat">
                    <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                </a>
                </div>
            </div>
            <div className="chat-feedback">
                <Link to="/feedback" className="feedback-link">
                Feedback
                </Link>
                
                <div className="chat-icon">
                <a className="chat-hover">
                    <Link to="/chat">
                    <Link to="/chat">
                        <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                    </Link>
                    </Link>
                </a>
                </div>
            </div>
            <div className="footer">
                <div className="footer-content">
                <div className="footer-left">
                    <div className="about-text-footer">
                    <br />
                    College Of Computer Science
                    <br />
                    634 Nedderman Hall
                    <br />
                    Arlington, TX 76019
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-links">
                    <Link to="/" className="footer-links-title">
                        HOME
                    </Link>
                    <Link to="/aboutus" className="footer-links-title">
                        ABOUT
                    </Link>
                    <Link to="/services" className="footer-links-title">
                        SERVICES
                    </Link>
                    <Link to="/contactUs" className="footer-links-title">
                        CONTACT US
                    </Link>
                    </div>
                </div>
                </div>
                <div>
                <br />
                <div className="footer-social-icons">
                    <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                    <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                    <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                    <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                </div>
                <br />
                @GradEdge Copyright 2023
                </div>
            </div>
            </div>
        </div>
        </div>
    </body>
    </html>
);
}

export default Student;

