// // import "./App.css";
// import {
// BrowserRouter as Router,
// Switch,
// Route,
// Redirect,
// } from "react-router-dom";

// // import Home component
import HomePage from "./homepage";
import AboutUs from "./aboutus";
import Signup from "./signup";
import Login from "./login";
import ForEveryRole from "./settings_foreveryrole";
import Student from "./student";
import StudentIssue from "./student_issue";
import StudentView from "./student_view";
import StudentView2 from "./student_view2";
import StudentView3 from "./student_view3";
import StudentExam from "./student_exams";
import StudentExam2 from "./student_exams2";
import StudentExam3 from "./student_exams3";
import StudentResult from "./student_result";
// import StudentResult2 from "./student_result2";
// import StudentResult3 from "./student_result3";
import Instructor from "./instructor";
import Analytics from "./qa_analytics";
import AdminFunctionality from "./admin_functionality";
import InstructorManageCourse from "./instmanagecourse";
import AddPolicy from "./addpolicy";
import CourseInfo from "./infocourse";
import Reports from "./instperformanceguidance";
import Feedback from "./instructorfeedback";
import InstructorProfile from "./instmanageprofile";
import UpdateProfile from "./instructorupdate";
import CreatePolicy from "./create_policies";
import ProgramCoordinatorDashboard from "./program_co";
import QAFeedback from "./qa_feedback";
import PerformanceData from "./pc_performance";
import Inquiries from "./pc_inquiries";
import Reviews from "./pc_reviews";
import Coordination from "./pc_coordination";
import QAOfficerDashboard from "./qaofficer";
import Guidelines from "./qa_compliance";
import CurriculumEvaluation from "./curriculum-evaluation";
import QAPolicies from "./qa-policies";
import UpdatePolicy from "./update_policies";
import QaCollaboration from "./qa-collaboration";
import AdminDashboard from "./admin";
import AdminGrant from "./admin_grant_permission";
import ManageUsers from "./admin_manage_users";
import AdminCrud from "./admin_crud";
import UpdateProfileAdmin from "./admin_edit";
import CreateProfile from "./admin_create";

import ForgotPassword from "./forgot_password";
import ContactUs from "./contactUs";
import Services from "./services";
import ResetPassword from "./reset_password";
import Mainfeedback from "./feedback";
import ManageStudent from "./manage_students";
import AddStudentCourse from "./add_student_course";
import AdminAddAttri from "./admin_add_attri";
import AdminManageAttri from "./admin_manage_attri";
import AddAdminPc from "./add_pc_admin";
import AddAdminQaofficer from "./add_qaofficer_admin";
import AddAdminCourse from "./add_admin_course";
import AdminManageCourse from "./admin_manage_course";
import AddProgramAdmin from "./add_program_admin";
import AdminManagePCCourse from "./admin_manage_pc_course";
import AdminManageProgram from "./admin_manage_program";
import AddQAAdminProgram from "./add_qa_admin_program";
import AdminManageQa from "./admin_manage_qa";
import AdminManageQaProgram from "./admin_manage__qa_program";
import InstructorAssesment from "./instructor_assessment";
import InstructorAssesmentAddExams from "./instructor_assessment_add_exams";
import InstructorAssesmentAddGrades from "./instructor_assessment_add_grades";
import InstructorAssesmentManageAssessment from "./instructor_assessment_manage_assessment";
import InstructorAssesmentManageGrade from "./instructor_assessment_manage_grade";
import StudentCourseinfo from "./student_courseinfo";
import PcGrantPermission from "./pc_grant_permission";
import PcUpdateCourse from "./pc_update_course";
import AdminFetchFeedback from "./admin_fetch_feedback";
import FeedbackReplies from "./feedback_replies";
import ManagePolicies from "./manage_policies";
import AdminSupport from "./admin_support";
import AdminReport from "./admin_report";
import AdminSupportStudent from "./admin_report_student";
import StudentReport from "./student_report";
import PcReport from "./pc_report";
import TestQuiz from "./test_quiz";
import StudentTakeQuiz from "./student_take_quiz";
import StudentReportInstructor from "./student_report_instructor";
import Overview from "./overview";
import ChatList from './chat';
import Chat from './chatInterface';
import NewChat from './NewChat';


import React from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import your components (unchanged)

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/settings_foreveryrole" element={<ForEveryRole />} />
        <Route path="/student" element={<Student />} />
        <Route path="/student_issue" element={<StudentIssue />} />
        <Route path="/student_view" element={<StudentView />} />
        <Route path="/student_view2" element={<StudentView2 />} />
        <Route path="/student_view3" element={<StudentView3 />} />
        <Route path="/student_exams" element={<StudentExam />} />
        <Route path="/student_exams2" element={<StudentExam2 />} />
        <Route path="/student_exams3" element={<StudentExam3 />} />
        <Route path="/student_result" element={<StudentResult />} />
        {/* <Route path="/student_result2" element={<StudentResult2 />} />
        <Route path="/student_result3" element={<StudentResult3 />} /> */}
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/qa_analytics" element={<Analytics />} />
        <Route path="/admin_functionality" element={<AdminFunctionality />} />
        <Route path="/instmanagecourse" element={<InstructorManageCourse />} />
        <Route path="/addpolicy" element={<AddPolicy />} />
        <Route path="/infocourse" element={<CourseInfo />} />
        <Route path="/instperformanceguidance" element={<Reports />} />
        <Route path="/instructorfeedback" element={<Feedback />} />
        <Route path="/instmanageprofile" element={<InstructorProfile />} />
        <Route path="/instructorupdate" element={<UpdateProfile />} />
        <Route path="/program_co" element={<ProgramCoordinatorDashboard />} />
        <Route path="/qa_feedback" element={<QAFeedback />} />
        <Route path="/pc_performance" element={<PerformanceData />} />
        <Route path="/pc_inquiries" element={<Inquiries />} />
        <Route path="/pc_reviews" element={<Reviews />} />
        <Route path="/pc_coordination" element={<Coordination />} />
        <Route path="/qaofficer" element={<QAOfficerDashboard />} />
        <Route path="/qa_compliance" element={<Guidelines />} />
        <Route path="/curriculum-evaluation" element={<CurriculumEvaluation />} />
        <Route path="/qa-policies" element={<QAPolicies />} />
        <Route path="/update_policies" element={<UpdatePolicy />} />
        <Route path="/create_policies" element={<CreatePolicy />} />
        <Route path="/qa-collaboration" element={<QaCollaboration />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin_grant_permission" element={<AdminGrant />} />
        <Route path="/admin_manage_users" element={<ManageUsers />} />
        <Route path="/admin_crud" element={<AdminCrud />} />
        <Route path="/admin_edit" element={<UpdateProfileAdmin />} />
        <Route path="/admin_create" element={<CreateProfile />} />
       
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/feedback" element={<Mainfeedback />} />
        <Route path="/manage_students" element={<ManageStudent />} />
        <Route path="/add_student_course" element={<AddStudentCourse />} />
        <Route path="/admin_add_attri" element={<AdminAddAttri />} />
        <Route path="/admin_manage_attri" element={<AdminManageAttri />} />
        <Route path="/add_pc_admin" element={<AddAdminPc />} />
        <Route path="/add_qaofficer_admin" element={<AddAdminQaofficer />} />
        <Route path="/add_admin_course" element={<AddAdminCourse />} />
        <Route path="/admin_manage_course" element={<AdminManageCourse />} />
        <Route path="/add_program_admin" element={<AddProgramAdmin />} />
        <Route path="/admin_manage_pc_course" element={<AdminManagePCCourse />} />
        <Route path="/admin_manage_program" element={<AdminManageProgram />} />
        <Route path="/add_qa_admin_program" element={<AddQAAdminProgram />} />
        <Route path="/admin_manage_qa" element={<AdminManageQa />} />
        <Route path="/admin_manage__qa_program" element={<AdminManageQaProgram />} />
        <Route path="/instructor_assessment" element={<InstructorAssesment />} />
        <Route path="/instructor_assessment_add_exams" element={<InstructorAssesmentAddExams />} />
        <Route path="/instructor_assessment_add_grades" element={<InstructorAssesmentAddGrades />} />
        <Route path="/instructor_assessment_manage_assessment" element={<InstructorAssesmentManageAssessment />} />
        <Route path="/instructor_assessment_manage_grade" element={<InstructorAssesmentManageGrade />} />
        <Route path="/student_courseinfo" element={<StudentCourseinfo />} />
        <Route path="/pc_grant_permission" element={<PcGrantPermission />} />
        <Route path="/pc_update_course" element={<PcUpdateCourse />} />
        <Route path="/admin_fetch_feedback" element={<AdminFetchFeedback />} />
        <Route path="/feedback_replies" element={<FeedbackReplies />} />
        <Route path="/manage_policies" element={<ManagePolicies />} />
        <Route path="/admin_support" element={<AdminSupport />} />
        <Route path="/admin_report_student" element={<AdminSupportStudent />} />
        <Route path="/admin_report" element={<AdminReport />} />
        <Route path="/pc_report" element={<PcReport />} />
        <Route path="/student_report" element={<StudentReport />} />
        <Route path="/test_quiz" element={<TestQuiz/>} />
        <Route path="/student_take_quiz" element={<StudentTakeQuiz/>} />
        <Route path="/overview" element={<Overview/>} />
        <Route path="/student_report_instructor" element={<StudentReportInstructor/>} />
        <Route path="/chat" element={<ChatList/>} />
            <Route path="/chat/:id" element={<Chat/>} />
            <Route path="/new-chat" element={<NewChat/>} />
            <Route path="/new-chat" element={<NewChat/>} />
        </Routes>
    </Router>
  );
}

export default App;
