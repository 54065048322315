// Chat.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';


function Chat() {
  const { id } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const currentUserID = localStorage.getItem('id');

  // useEffect(() => {
  //   fetchMessages();
  // }, [id]);
  // useEffect(() => {
    
  //   const source = axios.CancelToken.source();
  
  //   const fetchMessages = async () => {
  //     try {
  //       const response = await axios.get(`https://vxd1553.uta.cloud/php/chat.php?chatID=${id}`, {
  //         cancelToken: source.token,
  //       });
  //       setMessages(response.data);
  //     } catch (error) {
  //       if (axios.isCancel(error)) {
  //         console.log('Request canceled', error.message);
  //       } else {
  //         console.error('Error fetching messages:', error);
  //       }
  //     }
  //   };
  
  //   fetchMessages();
  
  //   // Cleanup function to cancel the request when the component is unmounted
  //   return () => {
  //     source.cancel('Component unmounted');
  //   };
  // }, [id]);
  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  
  //   const fetchMessages = async () => {
  //     try {
  //       const response = await axios.get(`https://vxd1553.uta.cloud/php/chat.php?chatID=${id}`, {
  //         cancelToken: source.token,
  //       });
  //       setMessages(response.data);
  //     } catch (error) {
  //       if (axios.isCancel(error)) {
  //         console.log('Request canceled', error.message);
  //       } else {
  //         console.error('Error fetching messages:', error);
  //       }
  //     }
  //   };
  
  //   // Fetch messages and set background color based on user's role
  //   const fetchDataAndSetBackgroundColor = async () => {
  //     // Fetch user's role or use your logic to determine it
  //     const userRole = 'Instructor'; // Replace this with your actual logic or user role retrieval
  
  //     // Set the background color based on the user's role
  //     document.body.style.backgroundColor = getBackgroundColor(userRole);
  
  //     // Fetch messages
  //     await fetchMessages();
  //   };
  
  //   // Call the combined function
  //   fetchDataAndSetBackgroundColor();
  
  //   // Cleanup function to cancel the request when the component is unmounted
  //   return () => {
  //     source.cancel('Component unmounted');
  //     // Reset the background color when the component is unmounted
  //     document.body.style.backgroundColor = '';
  //   };
  // }, [id]); // Dependency array with 'id'
  useEffect(() => {
    const source = axios.CancelToken.source();
  
    const fetchDataAndSetBackgroundColor = async () => {
      try {
        // Fetch messages
        const response = await axios.get(`https://vxd1553.uta.cloud/php/chat.php?chatID=${id}`, {
          cancelToken: source.token,
        });
  
        // Set messages
        setMessages(response.data);
  
        // Determine the role from the fetched messages
        const userRole = response.data.length > 0 ? response.data[0].receiver_role : 'DefaultRole';
  
        // Set the background color based on the user's role
        document.body.style.backgroundColor = getBackgroundColor(userRole);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.error('Error fetching messages:', error);
        }
      }
    };
  
    fetchDataAndSetBackgroundColor();
  
    // Cleanup function to cancel the request when the component is unmounted
    return () => {
      source.cancel('Component unmounted');
    };
  }, [id]);
  
  const fetchMessages = async () => {
    try {
      const response = await axios.get(`https://vxd1553.uta.cloud/php/chat.php?chatID=${id}`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async () => {
    try {
      await axios.post(`https://vxd1553.uta.cloud/php/sendMessage.php?chatID=${id}&id=${localStorage.getItem('id')}`, {
        message: newMessage,
      });
      // Refresh the messages after sending a new message
      fetchMessages();
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  const getBackgroundColor = (role) => {
    console.log('Role:', role);
    switch (role) {
      case 'Student':
        return 'yellow';
      case 'Instructor':
        return 'skyblue';
      case 'Program Coordinator':
        return 'pink';
      case 'QA Officer':
        return 'purple';
      case 'admin':
        return 'lightgreen';
      default:
        return 'red'; // Default color
    }
  };
  const staticConversation = [
    // Add more static messages as needed
  ];

  return (
    <div>
      <style>
        {`
          body {
            font-family: 'Arial', sans-serif;
            background-color: #f5f5f5;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
          }

          .chatbot {
            width: 400px;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            overflow: hidden;
          }

          .chatbot_header {
            background-color: #007bff;
            color: #fff;
            padding: 15px;
            text-align: center;
            font-size: 24px;
          }

          .chatbot_body {
            padding: 20px;
            height: 300px;
            overflow-y: auto;
          }

          .left-message, .right-message {
            margin-bottom: 15px;
            padding: 10px;
            border-radius: 8px;
          }

          .left-message {
            background-color: #f0f0f0;
            color: #333;
          }

          .right-message {
            background-color: #007bff;
            color: #fff;
            text-align: right;
          }

          .chatbot_footer {
            display: flex;
            align-items: center;
            padding: 15px;
          }

          .chatbot_footer--input {
            flex: 1;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-right: 10px;
          }

          .chatbot_footer--btn {
            padding: 10px 20px;
            background-color: #007bff;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }

          .chatbot_footer--btn:hover {
            background-color: #0056b3;
          }
        `}
      </style>
      
      <div className='chatbot'>
        <div className='chatbot_header'>
          Chat
        </div>

        <div className='chatbot_body'>
          {!Array.isArray(messages) && messages.length === 0 ? (
            <div className='chatbot_body'>
              {staticConversation.map((message, index) => (
                // <div key={index} className='left-message'>
                <div key={index} className='left-message' style={{ backgroundColor: getBackgroundColor(message.receiver_role) }}>
                  <p>
                    {message.name}: {message.message}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className='chatbot_body'>
        {Array.isArray(messages) ? (
  messages.map((message) => (
    <div
      key={message.id}
      className={message.s_id === currentUserID ? 'right-message' : 'left-message'}
    >
      <p>
        {message.s_id === currentUserID ? 'You' : message.receiver_name}: {message.message}
      </p>
    </div>
  ))
) : (
  <div className='left-message'>
  
  </div>
)}

            </div>
          )}
        </div>
        <div className='chatbot_footer'>
          <input
            className='chatbot_footer--input'
            name='newMessage'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            type='text'
            placeholder='Type here'
          />
          <button className='chatbot_footer--btn' onClick={handleSendMessage}>
            Send
          </button>
        </div>
      </div>
      <br>
      </br>
      <button className='chatbot_footer--btn' >

  <Link to="/chat">Home</Link>
</button>
    </div>
  );
}

export default Chat;
