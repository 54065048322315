// import React from 'react';
// import './style.css'; // Import your CSS file
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function AdminFunctionality() {
//     const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//     return (
//         <html lang="en">
//             <head>
//                 <link rel="stylesheet" href="style.css" />
//                 <title>Functionality</title>
//             </head>
//             <body className="vsc-initialized">
//                 <div id="root">
//                     <div className="App">
//                         <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/instructor" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                {/* <Link to="/" className="header-title">LOGOUT</Link> */}
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                     {/* <Link to="/login"className="header-title">LOGOUT</Link> */}
//                     <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
//                             </div>
//                         </div>
//                         <div>
//           <section className="services">
//             <h1>Guidelines should be followed!</h1>
//             <p>These are the detailed guidelines...</p>

//             <ul>
//               <li>
//                 <strong>Understand Quality Standards:</strong>
//                 <ul>
//                   <li>
//                     Familiarize yourself with industry-specific quality standards and regulations that apply to your organization's products or services.
//                   </li>
                  
//                 </ul>

//               </li>

//  <li><strong>Document Quality Procedures:</strong>
//                             <ul>
//                                 <li>Develop and maintain quality procedures, guidelines, and documentation to ensure consistency and compliance with quality standards.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Conduct Audits and Inspections:</strong>
//                             <ul>
//                                 <li>Perform regular audits and inspections of products, processes, and systems to identify and address non-conformities and deviations from quality standards.</li>
//                             </ul>
//                         </li>

//                         <li><strong>Establish Quality Metrics:</strong>
//                             <ul>
//                                 <li>Define key performance indicators (KPIs) and quality metrics to measure and track the performance of products and processes.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Root Cause Analysis:</strong>
//                             <ul>
//                                 <li>Investigate and analyze quality issues to identify root causes and implement corrective and preventive actions (CAPA).</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Supplier Quality Management:</strong>
//                             <ul>
//                                 <li>Evaluate and monitor the quality of materials and components supplied by external vendors and ensure they meet the required standards.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Process Improvement:</strong>
//                             <ul>
//                                 <li>Continuously seek opportunities for process improvement to enhance product quality, reduce defects, and optimize efficiency.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Training and Education:</strong>
//                             <ul>
//                                 <li>Provide training to employees on quality standards, procedures, and best practices to ensure everyone understands their role in maintaining quality.</li>
//                             </ul>
//                         </li>
//                         <li><strong>Regulatory Compliance:</strong>
//                             <ul>
//                                 <li>Stay updated on relevant industry regulations and standards to ensure compliance and avoid potential legal or regulatory issues.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Documentation Management:</strong>
//                             <ul>
//                                 <li>Maintain a robust document control system to manage and track quality-related documents and records.</li>
//                             </ul>
//                         </li>
                    
//                         <li><strong>Risk Assessment:</strong>
//                             <ul>
//                                 <li>Conduct risk assessments to identify potential quality-related risks and develop mitigation strategies.</li>
//                             </ul>
//                         </li>
//                         <li><strong>Reporting and Communication:</strong>
//                             <ul>
//                                 <li>Communicate quality-related issues and findings to management and relevant stakeholders. Prepare</li>
//                                 </ul>
//                                 </li>
                        
//                                 </ul>
//           </section>
//         </div>
//       </div>
//                 </div>   
//             </body>
//         </html>
//     );
// }

// export default AdminFunctionality;
import React from 'react';
import './style.css'; // Import your CSS file
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function AdminFunctionality() {
  const handleLogout = () => {
    const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
    if (confirmLogout) {
      // Use the <Link> component for navigation
      // Replace with your login page route
      return <Link to="/login" />;
    }
    // If the user chooses not to log out, the function will simply exit without any additional actions
  };

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Functionality</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <Link to="/"> {/* Use Link component for navigation */}
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </Link>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/instructor" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                {/* Use the Link component for logout */}
                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                  LOGOUT
                </span>
                <Link to="/settings_foreveryrole" className="header-title">PROFILE</Link>
              </div>
            </div>
            <div>
              <section className="services">
                <h1>Guidelines should be followed!</h1>
                <p>These are the detailed guidelines...</p>
                <ul>
                  <li>
                    <strong>Understand Quality Standards:</strong>
                    <ul>
                      <li>
                        Familiarize yourself with industry-specific quality standards and regulations that apply to your organization's products or services.
                      </li>
                    </ul>
                  </li>
          

 <li><strong>Document Quality Procedures:</strong>
                            <ul>
                                <li>Develop and maintain quality procedures, guidelines, and documentation to ensure consistency and compliance with quality standards.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Conduct Audits and Inspections:</strong>
                            <ul>
                                <li>Perform regular audits and inspections of products, processes, and systems to identify and address non-conformities and deviations from quality standards.</li>
                            </ul>
                        </li>

                        <li><strong>Establish Quality Metrics:</strong>
                            <ul>
                                <li>Define key performance indicators (KPIs) and quality metrics to measure and track the performance of products and processes.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Root Cause Analysis:</strong>
                            <ul>
                                <li>Investigate and analyze quality issues to identify root causes and implement corrective and preventive actions (CAPA).</li>
                            </ul>
                        </li>
                    
                        <li><strong>Supplier Quality Management:</strong>
                            <ul>
                                <li>Evaluate and monitor the quality of materials and components supplied by external vendors and ensure they meet the required standards.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Process Improvement:</strong>
                            <ul>
                                <li>Continuously seek opportunities for process improvement to enhance product quality, reduce defects, and optimize efficiency.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Training and Education:</strong>
                            <ul>
                                <li>Provide training to employees on quality standards, procedures, and best practices to ensure everyone understands their role in maintaining quality.</li>
                            </ul>
                        </li>
                        <li><strong>Regulatory Compliance:</strong>
                            <ul>
                                <li>Stay updated on relevant industry regulations and standards to ensure compliance and avoid potential legal or regulatory issues.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Documentation Management:</strong>
                            <ul>
                                <li>Maintain a robust document control system to manage and track quality-related documents and records.</li>
                            </ul>
                        </li>
                    
                        <li><strong>Risk Assessment:</strong>
                            <ul>
                                <li>Conduct risk assessments to identify potential quality-related risks and develop mitigation strategies.</li>
                            </ul>
                        </li>
                        <li><strong>Reporting and Communication:</strong>
                            <ul>
                                <li>Communicate quality-related issues and findings to management and relevant stakeholders. Prepare</li>
                                </ul>
                                </li>
                        
                                </ul>
              
              </section>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default AdminFunctionality;
