
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './style.css';

const PcUpdateCourse = () => {
  const [courses, setCourses] = useState([]);
  const [editing, setEditing] = useState({});
  const [updateData, setUpdateData] = useState({
    Course_id: '',
    Course_desc: '',
    Course_name: '',
    instructor_teaching: '',
    cource_objectives: '',
    program: '',
  });

  useEffect(() => {
    getAllCourses();
  }, []);

  async function getAllCourses() {
    try {
      const response = await axios.get('https://vxd1553.uta.cloud/php/admin_fetch_course.php');
      const { courses } = response.data;
      setCourses(courses);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function updateEntry(course_id) {
    try {
      await axios.post('https://vxd1553.uta.cloud/php/update_instructor.php', {
        course_id,
        instructor_teaching: updateData.instructor_teaching,
        Course_desc: updateData.Course_desc,
      });

      // Update the courses state with the new data
      const updatedCourses = courses.map((course) => {
        if (course.Course_id === course_id) {
          return {
            ...course,
            instructor_teaching: updateData.instructor_teaching,
            Course_desc: updateData.Course_desc,
          };
        }
        return course;
      });

      setCourses(updatedCourses);
      alert('Course updated successfully.');
      setEditing({ ...editing, [course_id]: false });
      setUpdateData({
        Course_id: '',
        Course_desc: '',
        Course_name: '',
        instructor_teaching: '',
        cource_objectives: '',
        program: '',
      });
    } catch (error) {
      console.error('Error updating course:', error);
    }
  }

  const handleLogout = () => {
    // Handle logout
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <a href="./">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </a>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/program_co" className="header-title">
              HOME
            </Link>
            <Link to="/aboutus" className="header-title">
              ABOUT
            </Link>
            <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
              LOGOUT
            </span>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Update Course</h2>
            <table className="policy-table">
              <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Course Desc</th>
                  <th>Course name</th>
                  <th>Instructor</th>
                  <th>Cource OBJ</th>
                  <th>Program</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(courses) && courses.length > 0 ? (
                  courses.map((course) => (
                    <tr key={course.Course_id}>
                      <td>{course.Course_id}</td>
                      <td>
                        {editing[course.Course_id] ? (
                          <input
                            type="text"
                            value={updateData.Course_desc}
                            onChange={(e) => setUpdateData({ ...updateData, Course_desc: e.target.value })}
                          />
                        ) : (
                          course.Course_desc
                        )}
                      </td>
                      <td>{course.Course_name}</td>
                      <td>
                        {editing[course.Course_id] ? (
                          <input
                            type="text"
                            value={updateData.instructor_teaching}
                            onChange={(e) => setUpdateData({ ...updateData, instructor_teaching: e.target.value })}
                          />
                        ) : (
                          course.instructor_teaching
                        )}
                      </td>
                      <td>{course.course_objectives}</td>
                      <td>{course.program}</td>
                      <td>
                        {editing[course.Course_id] ? (
                          <button onClick={() => updateEntry(course.Course_id)}>SAVE</button>
                        ) : (
                          <button onClick={() => setEditing({ ...editing, [course.Course_id]: true })}>
                            UPDATE
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No courses available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/" className="footer-links-title">
                    HOME
                  </Link>
                  <Link to="/aboutus" className="footer-links-title">
                    ABOUT
                  </Link>
                  <Link to="/services" className="footer-links-title">
                    SERVICES
                  </Link>
                  <Link to="/contactUs" className="footer-links-title">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <br />
              <div className="footer-social-icons">
                <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
              </div>
              <br />
              @GradEdge Copyright 2023
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PcUpdateCourse;