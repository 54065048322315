import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function ProgramCoordinatorDashboard() {
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <a href="/">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </a>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/program_co" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <Link to="/" className="header-title">LOGOUT</Link>
            {/* <span className="header-title">LOGOUT</span> */}
          </div>
        </div>
        <div className="main-content-container">
          <div className="qa-container">
            <nav>
              <ul>
                <li>
                  <a className="logo qa-link">
                    <span className="nav-item"><Link to="/program_co">Program Coordinator DashBoard</Link></span>
                  </a>
                </li>
                <br />
                {/* <li>
                  <i className="fas fa-ssf"></i>
                  <span className="nav-item">
                    <Link to="/qa_feedback" className="qa-link">Support and Feedback</Link>
                  </span>
                </li> */}
                 <li>
                  <i className="fas fa-ssf"></i>
                  <span className="nav-item">
                    <Link to="/overview" className="qa-link">Overview</Link>
                  </span>
                </li>
                <li>
                  <i className="fas fa-ssf"></i>
                  <span className="nav-item">
                    <Link to="/feedback_replies" className="qa-link">Feedback Acknowledgments</Link>
                  </span>
                </li>
                {/* <li>
                  <i className="fas fa-chart-bar"></i>
                  <span className="nav-item">
                    <Link to="/qa_analytics" className="qa-link">Analytics</Link>
                  </span>
                </li> */}

                <li>
                  <i className="fas fa-cog"></i>
                  <span className="nav-item">
                    <Link to="/settings_foreveryrole" className="qa-link">Settings</Link>
                  </span>
                </li>
              </ul>
            </nav>

            <section className="main">
              <div className="main-top">
                <br />
                <br />
                <br />

                <h1>Responsibilities</h1>
                <i className="fas fa-user-cog"></i>
              </div>
              <div className="main-skills">
                <div className="card">
                  <i className="fas fa-laptop-code"></i>
                  <h3>Grant Permission</h3>
                  <button>
                    <Link to="/pc_grant_permission" className="button-links">Get Started</Link>
                  </button>
                </div>
                <div className="card">
                  <i className="fab fa-wordpress"></i>
                  <h3>Update Course</h3>
                  <button>
                    <Link to="/pc_update_course" className="button-links">Get Started</Link>
                  </button>
                </div>
                <div className="card">
                  <i className="fab fa-app-store-ios"></i>
                  <h3>Program Evaluation</h3>
                  <button>
                    <Link to="/pc_report" className="button-links">Get Started</Link>
                  </button>
                </div>
                <div className="card">
                  <i className="fas fa-palette"></i>
                  <h3>Give Feedback</h3>
                  <button>
                    <Link to="/feedback" className="button-links">Get Started</Link>
                  </button>
                </div>
                {/* <div className="card">
                  <i className="fab fa-app-store-ios"></i>
                  <h3>Feedback Replies</h3>
                  <button>
                    <Link to="/feedback_replies" className="button-links">Get Started</Link>
                  </button>
                </div> */}
              
              </div>
            </section>
          </div>
          <div className="chat-feedback">
            <a href=" /feedback" className="feedback-link">Feedback</a>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <div>
              <br />
              <div className="footer-social-icons">
                <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
              </div>
              <br />
              @GradEdge Copyright 2023
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinatorDashboard;
