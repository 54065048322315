
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import './style.css';


// const ManageCourse = () => {
//   const [courses, setcourses] = useState([]);
//   useEffect(() => {
//     getAllUsers();
//   }, []); 

//   async function  getAllUsers  ()  {
//     try {
//       const response = await axios.get('https://vxd1553.uta.cloud/php/admin_fetch_course.php'); // Update the URL with your backend endpoint
//       const {courses}=response.data;
//       setcourses(courses); // Update state with fetched data
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   }
//   async function deleteEntry(Course_id) {
//     try {
//       await axios.post('https://vxd1553.uta.cloud/php/admin_delete_course.php', {
//         Course_id:  Course_id // Send the student_id in the request body
//       });
//       alert("Course deleted successfully.");
//       getAllUsers();
//     } catch (error) {
//       console.error("Error deleting student:", error);
//     }
//   }


//   const handleLogout = () => {

//   }

//   return (
//     <div id="root">
//       <div className="App">
//         <div className="header">
//           <div className="header-left">
//             <div className="header-title">
//               <Link to="/"> {/* Use Link component for navigation */}
//                 <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//               </Link>
//               <span className="heading">GradEdge</span>
//             </div>
//           </div>
//           <div className="header-right">
//             <Link to="/admin" className="header-title">HOME</Link>
//             <Link to="/aboutus" className="header-title">ABOUT</Link>
//             {/* Use the Link component for logout */}
//             <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
//               LOGOUT
//             </span>
//           </div>
//         </div>
//         <div className="main-content-container">
//           <div className="container">
//             <br />
//             <h2>Manage Course</h2>
//             <table className="policy-table">
//               <thead>
//                 <tr>
//                 <th>ID</th>
//                   <th>Course Description</th>
//                   <th>Course Name</th>
//                   <th>Instructor</th>
//                   <th>Course Objective</th>
//                   <th>Program</th>
//                   <th>Update</th>
//                   <th>Delete</th>
//                 </tr>
//                 </thead>
//               <tbody>
                
//               {Array.isArray(courses) && courses.length > 0 ?(courses.map((user, index) => (
                  
//                   <tr key={index}>
//                     <td>{user.Course_id}</td>
//                     <td>{user.Course_desc}</td>
//                     <td>{user.Course_name}</td>
//                     <td>{user.instructor_teaching}</td>
//                     <td>{user.course_objectives}</td>
//                     <td>{user.program}</td>


                    
//                     <td>
//                 <button onClick={() => deleteEntry(user.Course_id)} className="button-link">UPDATE</button>

//                 </td>
//                 <td>
//                 <button onClick={() => deleteEntry(user.Course_id)} className="button-link">DELETE</button>

//                 </td>
//                   </tr>
//                 ))):""
//             }
//               </tbody></table>
//           </div>
//           <div className="chat-feedback">
//             <Link to="/feedback" className="feedback-link">Feedback</Link>
//             <div className="chat-icon">
//               <a className="chat-hover">
//                 <Link to="/chat">
//                   <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
//                 </Link>
//               </a>
//             </div>
//           </div>
//           <div className="footer">
//             <div className="footer-content">
//               <div className="footer-left">
//                 <div className="about-text-footer">
//                   <br />
//                   College Of Computer Science
//                   <br />
//                   634 Nedderman Hall
//                   <br />
//                   Arlington, TX 76019
//                 </div>
//               </div>
//               <div className="footer-right">
//                 <div className="footer-links">
//                   <Link to="/" className="footer-links-title">HOME</Link>
//                   <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
//                   <Link to="/services" className="footer-links-title">SERVICES</Link>
//                   <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
//                 </div>
//               </div>
//             </div>
//             <br />
//             <div className="footer-social-icons">
//               <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//               <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//               <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//               <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//             </div>
//             <br />
//             @GradEdge Copyright 2023
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ManageCourse;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

const ManageCourse = () => {
  const [courses, setCourses] = useState([]);
  const [editing, setEditing] = useState({});  // Track editing status for each course
  const [updateData, setUpdateData] = useState({
    Course_desc: ''
  });

  useEffect(() => {
    getAllUsers();
  }, []);

  async function getAllUsers() {
    try {
      const response = await axios.get('https://vxd1553.uta.cloud/php/admin_fetch_course.php'); // Update the URL with your backend endpoint
      const { courses } = response.data;
      setCourses(courses); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async function deleteEntry(Course_id) {
    try {
      await axios.post('https://vxd1553.uta.cloud/php/admin_delete_course.php', {
        Course_id: Course_id // Send the Course_id in the request body
      });
      alert("Course deleted successfully.");
      getAllUsers();
    } catch (error) {
      console.error("Error deleting course:", error);
    }
  }

  // async function updateEntry(Course_id, Course_desc) {
  //   try {
  //     await axios.post('https://vxd1553.uta.cloud/php/admin_update_course.php', {
  //       Course_id,
  //       Course_desc,
  //     });

  //     // Fetch and update the courses after a successful update
  //     const response = await axios.get('https://vxd1553.uta.cloud/php/admin_fetch_course.php');
  //     const { courses } = response.data;
  //     setCourses(courses); // Update state with fetched data

  //     alert('Course updated successfully.');
  //     setEditing({ ...editing, [Course_id]: false }); // Stop editing
  //   } catch (error) {
  //     console.error('Error updating course:', error);
  //   }
  // }

  async function updateEntry(Course_id, Course_desc) {
    try {
      await axios.post('https://vxd1553.uta.cloud/php/admin_update_course.php', {
        Course_id,
        Course_desc,
      });
  
      // Fetch and update the courses after a successful update
      const response = await axios.get('https://vxd1553.uta.cloud/php/admin_fetch_course.php');
      const { courses } = response.data;
      setCourses(courses); // Update state with fetched data
  
      // Reset the updateData state to clear the input fields
      setUpdateData({
        Course_desc: ''
      });
  
      alert('Course updated successfully.');
      setEditing({ ...editing, [Course_id]: false }); // Stop editing
    } catch (error) {
      console.error('Error updating course:', error);
    }
  } 
  const handleLogout = () => {
    // Handle logout
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/"> {/* Use Link component for navigation */}
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/admin" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            {/* Use the Link component for logout */}
            <Link to="/" className="header-title">LOGOUT</Link>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Manage Course</h2>
            <table className="policy-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Course Description</th>
                  <th>Course Name</th>
                  <th>Instructor</th>
                  <th>Course Objective</th>
                  <th>Program</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(courses) && courses.length > 0 ? (courses.map((user, index) => (
                  <tr key={index}>
                    <td>{user.Course_id}</td>
                    <td>
                      {editing[user.Course_id] ? (
                        <input
                          type="text"
                          value={updateData.Course_desc}
                          onChange={(e) => setUpdateData({ ...updateData, Course_desc: e.target.value })}
                        />
                      ) : (
                        user.Course_desc
                      )}
                    </td>
                    <td>{user.Course_name}</td>
                    <td>{user.instructor_teaching}</td>
                    <td>{user.course_objectives}</td>
                    <td>{user.program}</td>
                    <td>
                      {editing[user.Course_id] ? (
                        <span className="action-icon" onClick={() => updateEntry(user.Course_id, updateData.Course_desc)}>
                          &#128449; {/* Save Folder Icon */}
                        </span>
                      ) : (
                        <span className="action-icon" onClick={() => setEditing({ ...editing, [user.Course_id]: true })}>
                          &#9998; {/* Pencil Icon */}
                        </span>
                      )}
                    </td>
                    <td>
                      <span className="action-icon" onClick={() => deleteEntry(user.Course_id)}>
                        &#128465; {/* Delete Icon */}
                      </span>
                    </td>
                  </tr>
                ))) : ""}
              </tbody>
            </table>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCourse;