import React, { Component } from "react";
//import "./style.css"; // Import your CSS file for styling

import axios from 'axios';


class QuizForm extends Component {
  constructor() {
    super();
    this.state = {
      quizId:"",
      questions: [],
      currentQuestion: {
        question: "",
        answers: ["", "", "", ""],
      },
    };
  }
  handleQuizIdChange = (e) => {
    const quizId = e.target.value;
    this.setState({ quizId });
  };
  handleQuestionChange = (e) => {
    const question = e.target.value;
    this.setState((prevState) => ({
      currentQuestion: { ...prevState.currentQuestion, question },
    }));
  };

  handleAnswerChange = (e, index) => {
    const answers = [...this.state.currentQuestion.answers];
    answers[index] = e.target.value;
    this.setState((prevState) => ({
      currentQuestion: { ...prevState.currentQuestion, answers },
    }));
  };

  addQuestion = () => {
    const { currentQuestion, questions } = this.state;
    if (currentQuestion.question.trim() === "") return;

    this.setState((prevState) => ({
      questions: [...prevState.questions, currentQuestion],
      currentQuestion: {
        question: "",
        answers: ["", "", "", ""],
      },
    }));
  };

  // saveQuiz = () => {
  //   // You can save the 'questions' array to your backend or perform any other action here.
  //   console.log("Quiz saved:", this.state.questions);
  // };
  saveQuiz = () => {
    const { quizId, questions } = this.state;
    const userEmail = sessionStorage.getItem('email');  
    const quizData = {
      quizId :quizId,
      instructorEmail: userEmail,
    

      questionsText: questions,
    };
    if (quizId.trim() === "") {
      alert("Please enter a Quiz ID before saving.");
      return;
    }
    axios
    .post('https://vxd1553.uta.cloud/php/add_question.php', quizData)
    .then(function (response) {
      console.log('Quiz created successfully:', response.data);
    // Handle the response as needed
  })
  .catch(function (error) {
    console.error('Error saving quiz:', error);
    // Handle the error as needed
  });
  };
  
  
    render() {
      const { quizId, currentQuestion, questions } = this.state;
  
      const containerStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: "Arial, sans-serif",
      };
  
      const titleStyle = {
        fontSize: "2.5rem",
        color: "#3498db",
        marginBottom: "20px",
        textTransform: "uppercase",
      };
  
      const formStyle = {
        width: "60%",
        marginBottom: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "20px",
        background: "#fff",
      };
  
      const inputStyle = {
        width: "100%",
        padding: "12px",
        margin: "8px 0",
        boxSizing: "border-box",
        borderRadius: "4px",
        border: "1px solid #ccc",
      };
  
      const addQuestionStyle = {
        backgroundColor: "#27ae60",
        color: "white",
        padding: "12px",
        marginTop: "10px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "none",
        fontSize: "1rem",
        transition: "background 0.3s ease",
      };
  
      const previewStyle = {
        width: "60%",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "20px",
        background: "#fff",
      };
  
      const saveButtonStyle = {
        backgroundColor: "#3498db",
        color: "white",
        padding: "12px",
        marginTop: "20px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "none",
        fontSize: "1.2rem",
        transition: "background 0.3s ease",
      };
  
      return (
        <div style={containerStyle}>
          <h1 style={titleStyle}>MCQ Quiz Creator</h1>
          <form style={formStyle}>
            <label>Enter Quiz ID:</label>
            <input
              type="text"
              value={quizId}
              onChange={this.handleQuizIdChange}
              style={inputStyle}
            />
            <h2>Add Questions</h2>
            {questions.length < 5 && (
              <div>
                <input
                  className="question-input"
                  type="text"
                  placeholder="Enter your question"
                  value={currentQuestion.question}
                  onChange={this.handleQuestionChange}
                  style={inputStyle}
                />
                <ul className="answer-list">
                  {currentQuestion.answers.map((answer, index) => (
                    <li key={index}>
                      <input
                        className="answer-input"
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={answer}
                        onChange={(e) => this.handleAnswerChange(e, index)}
                        style={inputStyle}
                      />
                    </li>
                  ))}
                </ul>
                <button
                  className="add-question-button"
                  type="button"
                  onClick={this.addQuestion}
                  style={addQuestionStyle}
                >
                  Add Question
                </button>
              </div>
            )}
          </form>
          <div style={previewStyle}>
            <h2>Preview Questions</h2>
            <ul className="question-list">
              {questions.map((question, questionIndex) => (
                <li key={questionIndex}>
                  <p className="question-text">{question.question}</p>
                  <ul className="answer-list">
                    {question.answers.map((answer, answerIndex) => (
                      <li key={answerIndex}>{answer}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          {questions.length > 0 && (
            <button
              className="save-quiz-button"
              type="button"
              onClick={this.saveQuiz}
              style={saveButtonStyle}
            >
              Save Quiz
            </button>
          )}
        </div>
      );
    }
  }
  
  export default QuizForm;
  