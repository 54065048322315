import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

const FeedbackReplies = () => {
  const [feedbacks, setfeedbacks] = useState([]);
  const [updateData, setUpdateData] = useState({
    sender:'',
      Message:'',
      reply_msg:''
  });
  const [editing, setEditing] = useState({}); // Track editing status for each score

  useEffect(() => {
    getAllUsers();
  }, []);

  async function getAllUsers() {
    try {
      const response = await axios.get('https://vxd1553.uta.cloud/php/reply_not_null.php');
      const { feedbacks } = response.data;
      setfeedbacks(feedbacks);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  const handleLogout = () => {
    // Handle logout
  };

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          {/* ... (Your header code) ... */}
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Manage Feedbacks</h2>
            <table className="policy-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>sender's email</th>
                  
                  <th>sender's Message</th>
                  <th>Reply Description</th>
               
                </tr>
              </thead>
              <tbody>
                {Array.isArray(feedbacks) && feedbacks.length > 0 ? (
                  feedbacks.map((user, index) => (
                    <tr key={index}>
                      <td>{user.Feedback_id}</td>
                      <td>{user.sender}</td>
                      <td>{user.Message}</td>
                     
                      <td>
                        {/* {editing[user.Feedback_id] ? (
                          <input
                            type="text"
                            value={updateData.reply_msg}
                            onChange={(e) => setUpdateData({ ...updateData, reply_msg: e.target.value })}
                          />
                        ) : (
                          user.reply_msg
                        )} */}
                        {user.reply_msg}
                      </td>
                     
                    </tr>
                  ))
                ) : (
                  ''
                )}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default FeedbackReplies;