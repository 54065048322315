import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from "react-router-dom";

function ResetPassword() {
  return (
    <div className="container">
      <div className="form-container">
        <h2>Reset Password</h2>
        <p>Enter your new password below.</p>
        <form action="#" method="POST">
          <input type="password" placeholder="New Password" name="new_password" required />
          <input type="password" placeholder="Confirm New Password" name="confirm_password" required />
          <button type="submit">Reset Password</button>
        </form>
        <p> <Link to="/login">Back to Login</Link></p>
      </div>
    </div>
  );
}

export default ResetPassword;
