// import React from 'react';
// import './style.css'; // Import your CSS file here
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function Coordination() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/program_co" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                                {/* <Link to="/" className="header-title">LOGOUT</Link> */}
//                             </div>
//                         </div>
//         <div className="main-content-container">
//           <div className="container">
//             <div className="form-container">
//               <h2>Coordination</h2>
//               <form action="#" method="POST">
//                 <div className="form-group">
//                   <label htmlFor="userType">User Type:</label>
//                   <select id="userType" name="userType">
//                     <option value="student">Student</option>
//                     <option value="professor">Professor</option>
//                   </select>
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="email">Email:</label>
//                   <input type="email" id="email" name="email" required />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="message">Message:</label>
//                   <textarea id="message" name="message" rows="4" required></textarea>
//                 </div>
//                 <button type="submit">Submit</button>
//               </form>
//             </div>
            
//           </div>
//            <div className="chat-feedback">
//           <Link to="/feedback" className="feedback-link">Feedback</Link>
//           {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
//             {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
//             <div className="chat-icon">
//               <a  className="chat-hover"><Link to="/chat">
//                <Link to="/chat">
//                 <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
//               </a>
//             </div>
//           </div>
//                             <div className="footer">
//               <div className="footer-content">
//                     <div className="footer-left">
//                       <div className="about-text-footer">
//                         <br />
//                         College Of Computer Science
//                         <br />
//                         634 Nedderman Hall
//                         <br />
//                         Arlington, TX 76019
//                       </div>
//                     </div>
//                     <div className="footer-right">
//                       <div className="footer-links">
//                         <Link to="/" className="footer-links-title">HOME</Link>
//                        <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
//                        <Link to="/services" className="footer-links-title">
//                           SERVICES</Link>
                   
//                        <Link to="/contactUs" className="footer-links-title">
//                           CONTACT US</Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     <br />
//                     <div className="footer-social-icons">
//                       <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
//                       <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
//                       <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
//                       <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
//                     </div>
//                     <br />
//                     @GradEdge Copyright 2023
//                   </div>              </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Coordination;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Import your CSS file here

function Login() {
  const [formData, setFormData] = useState({
    // role: '',
    email: '',
    password: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = formData;
    // Your authentication logic goes here
    if (email === 'student@gmail.com' && password === 'student') {
      // Redirect to the student dashboard
    } else if (email === 'instructor@gmail.com' && password === 'instructor') {
      // Redirect to the instructor dashboard
    } else if (email === 'admin@gmail.com' && password === 'admin') {
      // Redirect to the admin dashboard
    } else if (email === 'pc@gmail.com' && password === 'pc123') {
      // Redirect to the program coordinator dashboard
    } else if (email === 'qa@gmail.com' && password === 'qa123') {
      // Redirect to the QA officer dashboard
    } else {
      // Handle invalid credentials or show an error message
      alert('Invalid credentials');
    }
  };

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>Login</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
              <div className="header-left">
                <div className="header-title">
                  <Link to="/">
                    <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                  </Link>
                  <span className="heading">GradEdge</span>
                </div>
              </div>
              <div className="header-right">
                <Link to="/" className="header-title">HOME</Link>
                <Link to="/aboutus" className="header-title">ABOUT</Link>
                <Link to="/signup" className="header-title">SIGNUP</Link>
                <Link to="/login" className="header-title">LOGIN</Link>
                <Link to="/settings_foreveryrole" className="header-title">PROFILE</Link>
              </div>
            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Login</h2>
                  <form onSubmit={handleSubmit}>
                    {/* <input
                      type="text"
                      placeholder="Role"
                      name="role"
                      list="roles"
                      onChange={handleInputChange}
                      required
                    />
                    <datalist id="roles">
                      <option value="Student" />
                      <option value="Instructor" />
                      <option value="Admin" />
                      <option value="Program Coordinator" />
                      <option value="QA Officer" />
                    </datalist> */}
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={handleInputChange}
                      required
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={handleInputChange}
                      required
                    />
                    <button type="submit">Login</button>
                  </form>
                  <p>Don't have an account? <Link to="/signup">SIGNUP</Link></p>
                  <p> <Link to="/forgot_password">Forgot Password?</Link></p>
                </div>
              </div>
            </div>
            <div className="chat-feedback">
              <Link to="/feedback" className="feedback-link">Feedback</Link>
              <div className="chat-icon">
                <a className="chat-hover">
                  <Link to="/chat">
                    <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                  </Link>
                </a>
              </div>
            </div>
            <div className="footer">
              <div className="footer-content">
                <div className="footer-left">
                  <div className="about-text-footer">
                    <br />
                    College Of Computer Science
                    <br />
                    634 Nedderman Hall
                    <br />
                    Arlington, TX 76019
                  </div>
                </div>
                <div className="footer-right">
                  <div className="footer-links">
                    <Link to="/" className="footer-links-title">
                      HOME
                    </Link>
                    <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                    <Link to="/services" className="footer-links-title">SERVICES</Link>
                    <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                  </div>
                </div>
              </div>
              <div>
                <br />
                <div className="footer-social-icons">
                  <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                  <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                  <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                  <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                </div>
                <br />
                @GradEdge Copyright 2023
              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default Login;
