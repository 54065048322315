// ChatList.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function ChatList() {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const response = await axios.get(`https://vxd1553.uta.cloud/php/chatList.php?userID=${localStorage.getItem('id')}`);
        setChats(response.data);
      } catch (error) {
        console.error('Error fetching chat list:', error);
      }
    };

    fetchChats();
  }, []);

  return (
    <div>
      <style>
        {`
          body {
            font-family: 'Arial', sans-serif;
            background-color: #f8f9fa;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100vh;
          }

          .container {
            width: 800px;
            text-align: center;
          }

          .table-card {
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 30px;
            margin-top: 20px;
          }

          .home-text {
            font-size: 24px;
            color: #007bff;
            margin-bottom: 20px;
          }

          .start-chat-link {
            display: inline-block;
            padding: 12px 20px;
            background-color: #007bff;
            color: #fff;
            font-size: 16px;
            text-decoration: none;
            border-radius: 4px;
            margin-top: 20px;
            transition: background-color 0.3s ease;
          }

          .start-chat-link:hover {
            background-color: #0056b3;
          }

          .card-policy {
            width: 100%;
            margin-top: 20px;
            border-collapse: collapse;
          }

          .card-policy th, .card-policy td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #ddd;
          }

          .card-policy th {
            background-color: #f8f9fa;
            font-weight: bold;
          }

          .chat-link {
            color: #007bff;
            text-decoration: none;
            font-weight: bold;
          }

          .chat-link:hover {
            text-decoration: underline;
          }
        `}
      </style>

      <div className='container'>
        <div className="table-card">
          <h1 className="home-text">Chat List</h1>
          <Link className="start-chat-link" to="/new-chat">
            Start a New Chat
          </Link>
          <table className="card-policy">
            <thead>
              <tr>
                <th>Chat ID</th>
                <th>Sender Name</th>
                <th>Receiver Name</th>
              </tr>
            </thead>
            <tbody>
              {chats.map((chat) => (
                <tr key={chat.chat_id}>
                  <td>
                    <Link to={`/chat/${chat.chat_id}`} className="chat-link">
                      {chat.chat_id}
                    </Link>
                  </td>
                  <td>{chat.sender_name}</td>
                  <td>{chat.receiver_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br>
      </br>
      <button className='chatbot_footer--btn' >

  <Link to="/">Home</Link>
</button>
      </div>
    </div>
  );
}

export default ChatList;
