import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';

function Instructor() {
  const [activeTab, setActiveTab] = useState('Assessments');
  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  const tabContent = {
    'Assessments': (
      <div className="course">
        <div className="box">
          <h3>Manage Assessment</h3>
          <button style={{ backgroundColor: '#FF4500' }}>
            <Link to="/instructor_assessment_manage_assessment" className="button-links">
              Manage
            </Link>
          </button>
        </div>
        <div className="box">
          <h3>Manage Grades</h3>
          <button style={{ backgroundColor: '#FF4500' }}>
            <Link to="/instructor_assessment_manage_grade" className="button-links">
              Manage
            </Link>
          </button>
        </div>
        <div className="box">
          <h3>Stuent Progress Tracking</h3>
          <button style={{ backgroundColor: '#FF4500' }}>
            <Link to="/student_report_instructor" className="button-links">
              Generate
            </Link>
          </button>
        </div>
      </div>
    ),
    // 'Material': (
    //   <div className="course">
    //     <div className="box">
    //       <h3>Manage Coordinator Program</h3>
    //       <button style={{ backgroundColor: '#FF4500' }}>
    //         <Link to="/admin_manage_pc_course" className="button-links">
    //           Manage
    //         </Link>
    //       </button>
    //     </div>
    //     <div className="box">
    //       <h3>Manage Program</h3>
    //       <button style={{ backgroundColor: '#FF4500' }}>
    //         <Link to="/admin_manage_program " className="button-links">
    //           Manage
    //         </Link>
    //       </button>
    //     </div>
       
    //   </div>
    // ),
    // 'QA Officer': (
    //   <div className="course">
    //     <div className="box">
    //       <h3>Manage QA Officers</h3>
    //       <button style={{ backgroundColor: '#FF4500' }}>
    //         <Link to="/admin_manage_qa" className="button-links">
    //           Manage
    //         </Link>
    //       </button>
    //     </div>
    //     <div className="box">
    //       <h3>Manage Program</h3>
    //       <button style={{ backgroundColor: '#FF4500' }}>
    //         <Link to="/admin_manage_qa_program" className="button-links">
    //           Manage
    //         </Link>
    //       </button>
    //     </div>
    //     <div className="box">
    //       <h3>Reports</h3>
    //       <button style={{ backgroundColor: '#FF4500' }}>
    //         <Link to="/admin_manage_users" className="button-links">
    //           Generate
    //         </Link>
    //       </button>
    //     </div>
    //   </div>
    // ),
  };
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/instructor" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <Link to="/" className="header-title">LOGOUT</Link>
            {/* <span className="header-title">LOGOUT</span> */}
          </div>
        </div>
        <div className="main-content-container">
          <div className="qa-container">
            <nav>
              <ul>
                <li>
                  <a className="logo qa-link">
                    <span className="nav-item"><Link to="/instructor">Instructor DashBoard</Link></span>
                  </a>
                </li>
                <li>
                  <i className="fas fa-chart-bar"></i>
                  <span className="nav-item"><Link to="/instmanageprofile" className="qa-link">Profile</Link></span>
                </li>
                <li>
                  <i className="fas fa-ssf"></i>
                  <span className="nav-item"><Link to="/admin_functionality" className="qa-link">Functionality</Link></span>
                </li>
                <li>
                  <span className="nav-item"><Link to="/settings_foreveryrole" className="qa-link">Settings</Link></span>
                </li>
              </ul>
            </nav>
            <section className="main">
              <div className="main-top">
                <h1>Responsibilities</h1>
                <i className="fas fa-user-cog"></i>
              </div>
              <div className="main-skills">
                <div className="card">
                  <i className="fas fa-laptop-code"></i>
                  <h3>Manage Courses</h3>
                  <button><Link to="/instmanagecourse" className="button-links">Get Started</Link></button>
                </div>
                {/* <div className="card">
                  <i className="fab fa-wordpress"></i>
                  <h3>Performance Guidance</h3>
                  <button><Link to="/instperformanceguidance" className="button-links">Get Started</Link></button>
                </div> */}
                <div className="card">
                  <i className="fas fa-palette"></i>
                  <h3>Assessment</h3>
                  <button><Link to="/instructor_assessment" className="button-links">Get Started</Link></button>
                </div>
                <div className="card">
                  <i className="fas fa-palette"></i>
                  <h3>Exam Response</h3>
                  <button><Link to="/instructor_assessment" className="button-links">Get Started</Link></button>
                </div>
                <div className="card">
                  <i className="fas fa-palette"></i>
                  <h3>Feedback Acknowledgments</h3>
                  <button><Link to="/feedback_replies" className="button-links">Get Started</Link></button>
                </div>
                {/* <div className="card">
                  <i className="fab fa-app-store-ios"></i>
                  <h3>Profile</h3>
                  <button><Link to="/instmanageprofile" className="button-links">Get Started</Link></button>
                </div> */}
              </div>
              <section className="main-course">
                <h1>Manage</h1>
                <div className="course-box">
                  <div>
                    <ul className="nav">
                      {Object.keys(tabContent).map((tabKey) => (
                        <li
                          key={tabKey}
                          className={`nav-item ${activeTab === tabKey ? 'active' : ''}`}
                          onClick={() => handleTabChange(tabKey)}
                        >
                          <a className="nav-link" href="#">
                            {tabKey}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <div>{tabContent[activeTab]}</div>
                  </div>

                
                </div>
              </section>
              
            </section>
          </div>
          <div className="chat-feedback">
            <a href="./feedback.html" className="feedback-link">Feedback</a>
            <div className="chat-icon">
              <a href="./chat.html" className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <div>
              <br />
              <div className="footer-social-icons">
                <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                <img src="./images/linkedinLogo.png" className ="icons" width="50" alt="LinkedIn" />
                <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
              </div>
              <br />
              @GradEdge Copyright 2023
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructor;
