// import React from 'react';
// import './style.css'; // Import your CSS file here
// // import { Link } from "react-router-dom";
// import { Link, useHistory } from "react-router-dom";

// function Guidelines() {
//   const history = useHistory();
  
//     const handleLogout = () => {
//       const confirmLogout = window.confirm("ARE YOU SURE YOU WANT TO LOGOUT?");
//       if (confirmLogout) {
//         history.push('/login'); // Replace with your login page route
//       }
//       // If the user chooses not to log out, the function will simply exit without any additional actions
//     };
//   return (
//     <div id="root">
//       <div className="App">
//       <div className="header">
//                             <div className="header-left">
//                                 <div className="header-title">
//                                     <a href="./">
//                                         <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
//                                     </a>
//                                     <span className="heading">GradEdge</span>
//                                 </div>
//                             </div>
//                             <div className="header-right">
//                             <Link to="/qaofficer" className="header-title">HOME</Link>
//                                <Link to="/aboutus" className="header-title">ABOUT</Link>
//                                <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>LOGOUT</span>

//                                {/* <Link to="/" className="header-title">LOGOUT</Link> */}
//                                 {/* <Link to="/login" className="header-title">LOGIN</Link> */}
//                                <Link to="/settings_foreveryrole" className="header-title">PROFILE</Link>
//                           </div>
//                         </div>
//         <div>
//           <section className="services">
//             <h1>Guidelines should be followed!</h1>
//             <p>These are the detailed guidelines...</p>

//             <ul>
//               <li><strong>Understand Quality Standards:</strong> Familiarize yourself with industry-specific quality standards and regulations that apply to your organization's products or services.</li>
//               <li><strong>Document Quality Procedures:</strong> Develop and maintain quality procedures, guidelines, and documentation to ensure consistency and compliance with quality standards.</li>
//               <li><strong>Conduct Audits and Inspections:</strong> Perform regular audits and inspections of products, processes, and systems to identify and address non-conformities and deviations from quality standards.</li>
//                         <li><strong>Establish Quality Metrics:</strong> Define key performance indicators (KPIs) and quality metrics to measure and track the performance of products and processes.</li>
//                         <li><strong>Root Cause Analysis:</strong> Investigate and analyze quality issues to identify root causes and implement corrective and preventive actions (CAPA).</li>
//                         <li><strong>Supplier Quality Management:</strong> Evaluate and monitor the quality of materials and components supplied by external vendors and ensure they meet the required standards.</li>
//                         <li><strong>Process Improvement:</strong> Continuously seek opportunities for process improvement to enhance product quality, reduce defects, and optimize efficiency.</li>
//                         <li><strong>Training and Education:</strong> Provide training to employees on quality standards, procedures, and best practices to ensure everyone understands their role in maintaining quality.</li>
//                         <li><strong>Regulatory Compliance:</strong> Stay updated on relevant industry regulations and standards to ensure compliance and avoid potential legal or regulatory issues.</li>
//                         <li><strong>Documentation Management:</strong> Maintain a robust document control system to manage and track quality-related documents and records.</li>
//                         <li><strong>Risk Assessment:</strong> Conduct risk assessments to identify potential quality-related risks and develop mitigation strategies.</li>
//                         <li><strong>Reporting and Communication:</strong> Communicate quality-related issues and findings to management and relevant stakeholders. Prepare and distribute quality reports.</li>
//                         <li><strong>Continuous Improvement:</strong> Promote a culture of continuous improvement and quality consciousness throughout the organization.</li>
//                         <li><strong>Collaboration:</strong> Collaborate with cross-functional teams, including production, engineering, and R&D, to ensure quality objectives are met.</li>
//                         <li><strong>Documentation Review:</strong> Review and approve quality-related documents, including specifications, test protocols, and reports.</li>
//                         <li><strong>Customer Feedback:</strong> Monitor and analyze customer feedback and complaints to identify areas for improvement and ensure customer satisfaction.</li>
//                         <li><strong>Validation and Verification:</strong> Ensure that validation and verification processes are followed for new products or changes to existing products.</li>
//                         <li><strong>Training and Development:</strong> Stay updated on industry trends and best practices through training and professional development opportunities.</li>
//                         <li><strong>Non-Disclosure and Confidentiality:</strong> Maintain strict confidentiality regarding proprietary information and sensitive data related to quality and products.</li>
//                         <li><strong>Emergency Response:</strong> Be prepared to respond to quality emergencies and incidents promptly and effectively.</li>            </ul>
//           </section>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Guidelines;

import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from 'react-router-dom';

function Guidelines() {
  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <a href="./">
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </a>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/qaofficer" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            <span className="header-title" style={{ cursor: 'pointer' }}>LOGOUT</span>
            {/* <Link to="/" className="header-title">LOGOUT</Link> */}
            {/* <Link to="/login" className="header-title">LOGIN</Link> */}
            <Link to="/settings_foreveryrole" className="header-title">PROFILE</Link>
          </div>
        </div>
        <div>
          <section className="services">
            <h1>Guidelines should be followed!</h1>
            <p>These are the detailed guidelines...</p>

            <ul>
              <li>
                <strong>Understand Quality Standards:</strong> Familiarize yourself with industry-specific quality standards and regulations that apply to your organization's products or services.
              </li>
              <li>
                <strong>Document Quality Procedures:</strong> Develop and maintain quality procedures, guidelines, and documentation to ensure consistency and compliance with quality standards.
              </li>
              <li>
                <strong>Conduct Audits and Inspections:</strong> Perform regular audits and inspections of products, processes, and systems to identify and address non-conformities and deviations from quality standards.
              </li>
              <li>
                <strong>Establish Quality Metrics:</strong> Define key performance indicators (KPIs) and quality metrics to measure and track the performance of products and processes.
              </li>
              {/* Add the rest of the list items here */}
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Guidelines;
