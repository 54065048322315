import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from "react-router-dom";


function UpdateProfile() {
  return (
    <div id="root">
      <div className="App">
      <div className="header">
                            <div className="header-left">
                                <div className="header-title">
                                    <a href="./">
                                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                                    </a>
                                    <span className="heading">GradEdge</span>
                                </div>
                            </div>
                            <div className="header-right">
                            <Link to="/instructor" className="header-title">HOME</Link>
                               <Link to="/aboutus" className="header-title">ABOUT</Link>
                               <Link to="/" className="header-title">LOGOUT</Link>
                            </div>
                        </div>
        <div className="main-content-container">
          <div className="container">
            <div className="form-container">
              <h2>Update Info</h2>
              <form action="#" method="POST">
                <input type="text" placeholder="Your Name" name="name" required />
                <input type="email" placeholder="user.email@abc.com" name="email" required />
                <input
                  type="text"
                  placeholder="Courses Taught by you ',' separated"
                  name="courses"
                  required
                />
                <div className="form-group">
                  <label htmlFor="message">Bio:</label>
                  <textarea id="message" name="message" rows="4" required></textarea>
                </div>
                <button type="submit">Update</button>
              </form>
            </div>
          </div>
           <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
          {/* <a  className="feedback-link">Feedback <Link to="/feedback"></Link></a> */}
            {/* <a href="./feedback.html" className="feedback-link">Feedback</a> */}
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProfile;
