
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './style.css';


const AdminGrant = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []); 

  async function  getAllUsers  ()  {
    try {
      const response = await axios.get('https://vxd1553.uta.cloud/php/get_users.php'); // Update the URL with your backend endpoint
      setUsers(response.data.users); // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function approveUser(user){
    // await axios.post('https://vxd1553.uta.cloud/php/admin_approval.php', {email: user}).then((res)=> {
    //   console.log("user:", res.data);
    //   getAllUsers();
    try {
      await axios.post('https://vxd1553.uta.cloud/php/admin_approval.php', { email: user });
      // Update the state after successful approval
      getAllUsers();
      // Show an alert message
      alert(`User ${user} has been approved successfully!`);
    } catch (error) {
      console.error('Error approving user:', error);
    }   
  }
  const rejectUser = (user) => {
    axios.post('https://vxd1553.uta.cloud/php/admin_disapproval.php', {email: user}).then((res)=> {
      console.log("user:", res.data);
      getAllUsers();
    })
  }

  const handleLogout = () => {

  }

  return (
    <div id="root">
      <div className="App">
        <div className="header">
          <div className="header-left">
            <div className="header-title">
              <Link to="/"> {/* Use Link component for navigation */}
                <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
              </Link>
              <span className="heading">GradEdge</span>
            </div>
          </div>
          <div className="header-right">
            <Link to="/admin" className="header-title">HOME</Link>
            <Link to="/aboutus" className="header-title">ABOUT</Link>
            {/* Use the Link component for logout */}
            <span className="header-title" onClick={handleLogout} style={{ cursor: 'pointer' }}>
              LOGOUT
            </span>
          </div>
        </div>
        <div className="main-content-container">
          <div className="container">
            <br />
            <h2>Grant Permission</h2>
            <table className="policy-table">
            
              <thead>
                <tr>
                <th>ID</th>
                  <th>ROLE</th>
                  <th>FIRST NAME</th>
                  <th>LAST NAME</th>
                  <th>EMAIL</th>
                  <th>ROLE</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
                </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.id}</td>
                    <td>{user.role}</td>
                    <td>{user.f_name}</td>
                    <td>{user.l_name}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{user.is_approved === "1"? "Approved" :"DisApproved"}</td>
                    <td>
                    {user.is_approved === "1" ? (
         
         <button onClick={()=> rejectUser(user.email)} className="button-link">DISAPPROVE</button> ):
                      (<button onClick={()=> approveUser(user.email)} className="button-link">APPROVE</button>)
                      }
                    </td>
                  </tr>
                ))}
              </tbody></table>
          </div>
          <div className="chat-feedback">
            <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a className="chat-hover">
                <Link to="/chat">
                  <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" />
                </Link>
              </a>
            </div>
          </div>
          <div className="footer">
            <div className="footer-content">
              <div className="footer-left">
                <div className="about-text-footer">
                  <br />
                  College Of Computer Science
                  <br />
                  634 Nedderman Hall
                  <br />
                  Arlington, TX 76019
                </div>
              </div>
              <div className="footer-right">
                <div className="footer-links">
                  <Link to="/" className="footer-links-title">HOME</Link>
                  <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
                  <Link to="/services" className="footer-links-title">SERVICES</Link>
                  <Link to="/contactUs" className="footer-links-title">CONTACT US</Link>
                </div>
              </div>
            </div>
            <br />
            <div className="footer-social-icons">
              <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
              <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
              <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
              <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
            </div>
            <br />
            @GradEdge Copyright 2023
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminGrant;
