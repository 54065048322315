import React from 'react';
import './style.css'; // Import your CSS file here
import { Link } from "react-router-dom";

function Services() {
  return (
    <div id="root">
      <div className="App">
      <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="/">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/"className="header-title">HOME</Link>
                  
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  {/* <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link> */}
                  </div>            </div>
        <section className="services">
          <h1>Our Services</h1>
          <p>We offer a range of services to help you succeed in your academic journey. Here are some of our key services:</p>
          <ul>
            <li><strong>Course Selection:</strong> Our experienced advisors will assist you in selecting the right courses for your academic goals.</li>
            <li><strong>Research Opportunities:</strong> Explore various research opportunities and projects in the field of computer science.</li>
            <li><strong>Career Guidance:</strong> Get expert guidance on your career prospects and job placements.</li>
            <li><strong>Student Support:</strong> We provide academic and emotional support to help you excel in your studies.</li>
          </ul>
        </section>
       
        </div>
      </div>
    
  );
}

export default Services;
