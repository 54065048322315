import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const AddStudent = () => {
  const [formData, setFormData] = useState({
    tableName: 'manage_students',
    columns: {
      student_name:'',
      student_email:'',
      course_name:'',
      program_name:''
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Handle checkbox input separately to set a boolean value
    setFormData({
      ...formData,
      columns: {
        ...formData.columns,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    axios
      .post('https://vxd1553.uta.cloud/php/add_student_course.php', formData.columns)
      .then(function (response) {
        console.log('Response from server:', response.data);
  
        if (response.data.success) {
          if (response.data.success === 1) {
            if (response.data.message === 'Student already added to the same course.') {
              console.log('Existing Student:', response.data.existing_student);
              alert('Student is already added to the same course.');
            } else {
              alert('Student added successfully.');
            }
          } else {
            console.error(response.data.message);
            alert('Failed to add student. Please try again.');
          }
        } else {
          console.error(response.data.message);
          alert('Failed to add student. Please try again.');
        }
      })
      .catch(function (error) {
        console.error('Network error:', error);
        alert('Failed to add student due to a network error. Please try again.');
      });
  };
  
  
  
  
  

  return (
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" />
        <title>AddStudent</title>
      </head>
      <body className="vsc-initialized">
        <div id="root">
          <div className="App">
            <div className="header">
            <div className="header-left">
                    <div className="header-title">
                      <a href="./">
                        <img className="header-logo" src="./images/main_logo.jpeg" width="25px" alt="logo" />
                      </a>
                      <span className="heading">GradEdge</span>
                    </div>
                  </div>
                  <div className="header-right">
                  <Link to="/"className="header-title">HOME</Link>
                  <Link to="/aboutus"className="header-title">ABOUT</Link>
                  <Link to="/signup"className="header-title">SIGNUP</Link>
                  <Link to="/login"className="header-title">LOGIN</Link>
                  <Link to="/settings_foreveryrole"className="header-title">PROFILE</Link>
                  </div>            </div>
            <div className="main-content-container">
              <div className="container">
                <div className="form-container">
                  <h2>Add Student</h2>
                  <form onSubmit={handleSubmit}>
                  <input type="text" placeholder="course_id" name="course_id" value={formData.columns.course_id}
          onChange={handleChange} required />
                    <input type="text" placeholder="student_name" name="student_name" value={formData.columns.student_name}
          onChange={handleChange} required />
                    <input type="email" placeholder="student_email" name="student_email" value={formData.columns.student_email}
          onChange={handleChange} required />
                    <input type="text" placeholder="course_name" name="course_name" value={formData.columns.course_name}
          onChange={handleChange}required />
                    <input type="text" placeholder="program_name" name="program_name" value={formData.columns.program_name}
          onChange={handleChange} required />
                   
                    <button type="submit">Submit</button>
                  </form>
                 
                </div>
              </div>
               <div className="chat-feedback">
          <Link to="/feedback" className="feedback-link">Feedback</Link>
            <div className="chat-icon">
              <a  className="chat-hover"><Link to="/chat">
               <Link to="/chat">
                <img src="./images/chat.png" alt="Chat Icon" className="image-with-hover" /></Link></Link>
              </a>
            </div>
          </div>
                            <div className="footer">
              <div className="footer-content">
                    <div className="footer-left">
                      <div className="about-text-footer">
                        <br />
                        College Of Computer Science
                        <br />
                        634 Nedderman Hall
                        <br />
                        Arlington, TX 76019
                      </div>
                    </div>
                    <div className="footer-right">
                      <div className="footer-links">
                        <Link to="/" className="footer-links-title">
  HOME
</Link>
                       <Link to="/aboutus" className="footer-links-title">ABOUT</Link>
               
                       <Link to="/services" className="footer-links-title">
                          SERVICES</Link>
                   
                       <Link to="/contactUs" className="footer-links-title">
                          CONTACT US</Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div className="footer-social-icons">
                      <img src="./images/facebookLogo.png" className="icons" width="50" alt="Facebook" />
                      <img src="./images/instagramLogo.png" className="icons" width="50" alt="Instagram" />
                      <img src="./images/linkedinLogo.png" className="icons" width="50" alt="LinkedIn" />
                      <img src="./images/twitterLogo.png" className="icons" width="50" alt="Twitter" />
                    </div>
                    <br />
                    @GradEdge Copyright 2023
                  </div>              </div>
            </div>
          </div>
        </div>
      </body>
    </html>
  );
};

export default AddStudent;